.table-accounts-div{
  height: 820px;
  overflow-y: auto
}

.table-balance-div{
  height: 820px;
  overflow-y: auto
}

.accounts-list th,
.accounts-list td {
  padding: 5px !important;
  width: 16%;
  font-size: 12px;
}


.table-accounts-list  tbody th,
.table-accounts-list tbody td {
  padding: 5px 5px 5px 15px !important;
  width: 16%;
  font-size: 12px;
}

.table-accounts-list .btn-sm {
padding-top: 2px;
padding-bottom: 2px;
line-height: 1.1;
}

.table-accounts-list input {
  height:24px !important;
}


.account-actions .dropdown-menu::before {
  background: rgb(255, 255, 255) !important;
  border-top: 1px solid #2a2e39;
  top: -6px  !important;
  right: 90% !important;
  border-left: 1px solid #2a2e39;
}

.account-actions .dropdown-menu {
  background: rgb(255, 255, 255) !important;
  box-shadow: 0 0 10px 0 #000000 !important;
  border: 1px solid #2a2e39  !important;
  inset: 35px auto auto 0 !important;
}
.account-actions .dropdown-item:focus,
.account-actions .dropdown-item:hover {
  background-color: #217ace  !important;
  color: #ffffff !important;
}


.form-row>.col, .form-row>[class*=col-] {
  margin-top: 20px;
}
