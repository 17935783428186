.table-orders-div{
  height: 350px;
  overflow-y: auto
}

.orders-list th,
.orders-list td {
  padding: 5px !important;
  width: 16%;
  font-size: 12px;
}



.table-orders-list  tbody th,
.table-orders-list tbody td {
  padding: 5px 5px 5px 15px !important;
  width: 16%;
  font-size: 12px;
}

.table-orders-list .btn-sm {
padding-top: 2px;
padding-bottom: 2px;
line-height: 1.1;
}

