.market-pairs {
  border: 1px solid #e0e3eb;
  padding-top: 7px;
  border-radius: 2px;
}
.market-pairs .input-group {
  padding: 0 7px;
}
.market-pairs span {
  background: transparent;
}

.market-pairs input {
  border-color: #e0e3eb;
  border-left: 0;
  font-weight: 300;
}
.market-pairs input:focus {
  box-shadow: none;
  border-color: #e0e3eb;
}
.market-pairs .nav {
  margin-top: 12px;
  background: #f5f9fc;
  border-bottom: transparent;
}

.market-pairs .nav-link {
  color: #4a4a4a;
  padding: 10px 13px;
}
.market-pairs .nav-link:hover {
  border-color: transparent;
}
.market-pairs .nav-link.active {
  color: #007bff;
  background: transparent;
  border-color: transparent;
}

.market-pairs .table {
  margin-bottom: 0;
}
.market-pairs .table th,
.market-pairs .table td {
  font-size: 12px;
  padding: 5px 9px;
  cursor: pointer;
}

.market-pairs tbody tr td i {
  color: #75869696;
}
.market-pairs tbody tr td i.beacon {
  color: #fff;
}
.star-active tbody tr td i {
  color: #007bff;
}
.market-pairs thead tr th,
.market-pairs tbody tr td {
  font-weight: 400;
}

.market-pairs tbody {
  height: 800px;
  overflow-y: auto;
  display: block;
}
.no-fluid .market-pairs tbody {
  height: 815px;
}
.market-pairs table,
.market-pairs tr {
  width: 100%;
  table-layout: fixed;
}
.market-pairs tr {
  float: left;
  clear: both;
  overflow: hidden;
}

.market-pairs thead th,
.market-pairs tbody td {
  width: 55%;
}
