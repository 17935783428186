@font-face {
  font-family: "beacons";
  src: url("./beacons.eot#iefix") format("embedded-opentype"),
    url("./beacons.woff2") format("woff2"), url("./beacons.woff") format("woff"),
    url("./beacons.ttf") format("truetype");
}

.beacon {
  font-family: "beacons";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.exc-_default-s:before {
  content: "\f101"
}

.exc-_default:before {
  content: "\f102"
}

.sym-_default-s:before {
  content: "\f15d"
}

.sym-_default:before {
  content: "\f15e"
}

.sym-d:before {
  content: "\f15e"
}

.sym-d-s:before {
  content: "\f15d"
}

.sym-default:before {
  content: "\f15e"
}

.sym-default-s:before {
  content: "\f15d"
}

.exc-d:before {
  content: "\f102"
}

.exc-d-s:before {
  content: "\f101"
}

.exc-default:before {
  content: "\f102"
}

.exc-default-s:before {
  content: "\f101"
}

.cur-default:before {
  content: "\f15e"
}

.cur-default-s:before {
  content: "\f15d"
}

.exc-axieinfinity-s:before {
  content: "\f103"
}

.exc-axieinfinity:before {
  content: "\f104"
}

.exc-bibox-s:before {
  content: "\f105"
}

.exc-bibox:before {
  content: "\f106"
}

.exc-binance-s:before {
  content: "\f107"
}

.exc-binance:before {
  content: "\f108"
}

.exc-bisq-s:before {
  content: "\f109"
}

.exc-bisq:before {
  content: "\f10a"
}

.exc-bitbay-s:before {
  content: "\f10b"
}

.exc-bitbay:before {
  content: "\f10c"
}

.exc-bitfinex-s:before {
  content: "\f10d"
}

.exc-bitfinex:before {
  content: "\f10e"
}

.exc-bitflyer-s:before {
  content: "\f10f"
}

.exc-bitflyer:before {
  content: "\f110"
}

.exc-bithumb-s:before {
  content: "\f111"
}

.exc-bithumb:before {
  content: "\f112"
}

.exc-bitmex-s:before {
  content: "\f113"
}

.exc-bitmex:before {
  content: "\f114"
}

.exc-bitso-s:before {
  content: "\f115"
}

.exc-bitso:before {
  content: "\f116"
}

.exc-bitsquare-s:before {
  content: "\f117"
}

.exc-bitsquare:before {
  content: "\f118"
}

.exc-bitstamp-s:before {
  content: "\f119"
}

.exc-bitstamp:before {
  content: "\f11a"
}

.exc-bittrex-s:before {
  content: "\f11b"
}

.exc-bittrex:before {
  content: "\f11c"
}

.exc-bitvc-s:before {
  content: "\f11d"
}

.exc-bitvc:before {
  content: "\f11e"
}

.exc-btcchina-s:before {
  content: "\f11f"
}

.exc-btcchina:before {
  content: "\f120"
}

.exc-btce-s:before {
  content: "\f121"
}

.exc-btce:before {
  content: "\f122"
}

.exc-cexio-s:before {
  content: "\f123"
}

.exc-cexio:before {
  content: "\f124"
}

.exc-cme-s:before {
  content: "\f125"
}

.exc-cme:before {
  content: "\f126"
}

.exc-coinbasepro-s:before {
  content: "\f127"
}

.exc-coinbasepro:before {
  content: "\f128"
}

.exc-coinone-s:before {
  content: "\f129"
}

.exc-coinone:before {
  content: "\f12a"
}

.exc-cryptofacilities-s:before {
  content: "\f12b"
}

.exc-cryptofacilities:before {
  content: "\f12c"
}

.exc-deribit-s:before {
  content: "\f12d"
}

.exc-deribit:before {
  content: "\f12e"
}

.exc-dex-aggregated-s:before {
  content: "\f12f"
}

.exc-dex-aggregated:before {
  content: "\f130"
}

.exc-gateio-s:before {
  content: "\f131"
}

.exc-gateio:before {
  content: "\f132"
}

.exc-hitbtc-s:before {
  content: "\f133"
}

.exc-hitbtc:before {
  content: "\f134"
}

.exc-kucoin-s:before {
  content: "\f135"
}

.exc-kucoin:before {
  content: "\f136"
}

.exc-liquid-s:before {
  content: "\f137"
}

.exc-liquid:before {
  content: "\f138"
}

.exc-luno-s:before {
  content: "\f139"
}

.exc-luno:before {
  content: "\f13a"
}

.exc-mtgox-s:before {
  content: "\f13b"
}

.exc-mtgox:before {
  content: "\f13c"
}

.exc-mxc-s:before {
  content: "\f13d"
}

.exc-mxc:before {
  content: "\f13e"
}

.exc-nbatopshop-s:before {
  content: "\f13f"
}

.exc-nbatopshop:before {
  content: "\f140"
}

.exc-okcoin-s:before {
  content: "\f141"
}

.exc-okcoin:before {
  content: "\f142"
}

.exc-okex-s:before {
  content: "\f143"
}

.exc-okex:before {
  content: "\f144"
}

.exc-opensea-s:before {
  content: "\f145"
}

.exc-opensea:before {
  content: "\f146"
}

.exc-poloniex-s:before {
  content: "\f147"
}

.exc-poloniex:before {
  content: "\f148"
}

.exc-qryptos-s:before {
  content: "\f149"
}

.exc-qryptos:before {
  content: "\f14a"
}

.exc-quadrigacx-s:before {
  content: "\f14b"
}

.exc-quadrigacx:before {
  content: "\f14c"
}

.exc-quoine-s:before {
  content: "\f14d"
}

.exc-quoine:before {
  content: "\f14e"
}

.exc-rarible-s:before {
  content: "\f14f"
}

.exc-rarible:before {
  content: "\f150"
}

.exc-superrare-s:before {
  content: "\f151"
}

.exc-superrare:before {
  content: "\f152"
}

.exc-totle-s:before {
  content: "\f153"
}

.exc-therocktrading-s:before {
  content: "\f334"
}

.exc-totle:before {
  content: "\f154"
}

.exc-upbit-s:before {
  content: "\f155"
}

.exc-upbit:before {
  content: "\f156"
}

.exc-vaultofsatoshi-s:before {
  content: "\f157"
}

.exc-vaultofsatoshi:before {
  content: "\f158"
}

.exc-wex-s:before {
  content: "\f159"
}

.exc-wex:before {
  content: "\f15a"
}

.exc-zaif-s:before {
  content: "\f15b"
}

.exc-zaif:before {
  content: "\f15c"
}

.sym-1inch-s:before {
  content: "\f15f"
}

.sym-1inch:before {
  content: "\f160"
}

.sym-1st-s:before {
  content: "\f161"
}

.sym-1st:before {
  content: "\f162"
}

.sym-6a-s:before {
  content: "\f163"
}

.sym-6a:before {
  content: "\f164"
}

.sym-6b-s:before {
  content: "\f165"
}

.sym-6b:before {
  content: "\f166"
}

.sym-6c-s:before {
  content: "\f167"
}

.sym-6c:before {
  content: "\f168"
}

.sym-6e-s:before {
  content: "\f169"
}

.sym-6e:before {
  content: "\f16a"
}

.sym-6j-s:before {
  content: "\f16b"
}

.sym-6j:before {
  content: "\f16c"
}

.sym-6l-s:before {
  content: "\f16d"
}

.sym-6l:before {
  content: "\f16e"
}

.sym-6m-s:before {
  content: "\f16f"
}

.sym-6m:before {
  content: "\f170"
}

.sym-6n-s:before {
  content: "\f171"
}

.sym-6n:before {
  content: "\f172"
}

.sym-6s-s:before {
  content: "\f173"
}

.sym-6s:before {
  content: "\f174"
}

.sym-a38-s:before {
  content: "\f175"
}

.sym-a38:before {
  content: "\f176"
}

.sym-aac-s:before {
  content: "\f177"
}

.sym-aac:before {
  content: "\f178"
}

.sym-aave-s:before {
  content: "\f179"
}

.sym-aave:before {
  content: "\f17a"
}

.sym-abbc-s:before {
  content: "\f17b"
}

.sym-abbc:before {
  content: "\f17c"
}

.sym-abt-s:before {
  content: "\f17d"
}

.sym-abt:before {
  content: "\f17e"
}

.sym-abyss-s:before {
  content: "\f17f"
}

.sym-abyss:before {
  content: "\f180"
}

.sym-aca-s:before {
  content: "\f181"
}

.sym-aca:before {
  content: "\f182"
}

.sym-acat-s:before {
  content: "\f183"
}

.sym-acat:before {
  content: "\f184"
}

.sym-ach-s:before {
  content: "\f185"
}

.sym-ach:before {
  content: "\f186"
}

.sym-act-s:before {
  content: "\f187"
}

.sym-act:before {
  content: "\f188"
}

.sym-ada-s:before {
  content: "\f189"
}

.sym-ada:before {
  content: "\f18a"
}

.sym-adel-s:before {
  content: "\f18b"
}

.sym-adel:before {
  content: "\f18c"
}

.sym-adh-s:before {
  content: "\f18d"
}

.sym-adh:before {
  content: "\f18e"
}

.sym-adm-s:before {
  content: "\f18f"
}

.sym-adm:before {
  content: "\f190"
}

.sym-ado-s:before {
  content: "\f191"
}

.sym-ado:before {
  content: "\f192"
}

.sym-adt-s:before {
  content: "\f193"
}

.sym-adt:before {
  content: "\f194"
}

.sym-adx-s:before {
  content: "\f195"
}

.sym-adx:before {
  content: "\f196"
}

.sym-ae-s:before {
  content: "\f197"
}

.sym-ae:before {
  content: "\f198"
}

.sym-aeon-s:before {
  content: "\f199"
}

.sym-aeon:before {
  content: "\f19a"
}

.sym-aep-s:before {
  content: "\f19b"
}

.sym-aep:before {
  content: "\f19c"
}

.sym-aergo-s:before {
  content: "\f19d"
}

.sym-aergo:before {
  content: "\f19e"
}

.sym-agi-s:before {
  content: "\f19f"
}

.sym-agi:before {
  content: "\f1a0"
}

.sym-aid-s:before {
  content: "\f1a1"
}

.sym-aid:before {
  content: "\f1a2"
}

.sym-aion-s:before {
  content: "\f1a3"
}

.sym-aion:before {
  content: "\f1a4"
}

.sym-air-s:before {
  content: "\f1a5"
}

.sym-air:before {
  content: "\f1a6"
}

.sym-akro-s:before {
  content: "\f1a7"
}

.sym-akro:before {
  content: "\f1a8"
}

.sym-algo-s:before {
  content: "\f1a9"
}

.sym-algo:before {
  content: "\f1aa"
}

.sym-ali-s:before {
  content: "\f1ab"
}

.sym-ali:before {
  content: "\f1ac"
}

.sym-alpha-s:before {
  content: "\f1ad"
}

.sym-alpha:before {
  content: "\f1ae"
}

.sym-amb-s:before {
  content: "\f1af"
}

.sym-amb:before {
  content: "\f1b0"
}

.sym-amlt-s:before {
  content: "\f1b1"
}

.sym-amlt:before {
  content: "\f1b2"
}

.sym-amp-s:before {
  content: "\f1b3"
}

.sym-amp:before {
  content: "\f1b4"
}

.sym-ampl-s:before {
  content: "\f1b5"
}

.sym-ampl:before {
  content: "\f1b6"
}

.sym-anct-s:before {
  content: "\f1b7"
}

.sym-anct:before {
  content: "\f1b8"
}

.sym-ankr-s:before {
  content: "\f1b9"
}

.sym-ankr:before {
  content: "\f1ba"
}

.sym-ant-s:before {
  content: "\f1bb"
}

.sym-ant:before {
  content: "\f1bc"
}

.sym-api3-s:before {
  content: "\f1bd"
}

.sym-api3:before {
  content: "\f1be"
}

.sym-apis-s:before {
  content: "\f1bf"
}

.sym-apis:before {
  content: "\f1c0"
}

.sym-appc-s:before {
  content: "\f1c1"
}

.sym-appc:before {
  content: "\f1c2"
}

.sym-ar-s:before {
  content: "\f1c3"
}

.sym-ar:before {
  content: "\f1c4"
}

.sym-ardr-s:before {
  content: "\f1c5"
}

.sym-ardr:before {
  content: "\f1c6"
}

.sym-ark-s:before {
  content: "\f1c7"
}

.sym-ark:before {
  content: "\f1c8"
}

.sym-arn-s:before {
  content: "\f1c9"
}

.sym-arn:before {
  content: "\f1ca"
}

.sym-arpa-s:before {
  content: "\f1cb"
}

.sym-arpa:before {
  content: "\f1cc"
}

.sym-art-s:before {
  content: "\f1cd"
}

.sym-art:before {
  content: "\f1ce"
}

.sym-aspt-s:before {
  content: "\f1cf"
}

.sym-aspt:before {
  content: "\f1d0"
}

.sym-ast-s:before {
  content: "\f1d1"
}

.sym-ast:before {
  content: "\f1d2"
}

.sym-astr-s:before {
  content: "\f1d3"
}

.sym-astr:before {
  content: "\f1d4"
}

.sym-at-s:before {
  content: "\f1d5"
}

.sym-at:before {
  content: "\f1d6"
}

.sym-atm-s:before {
  content: "\f1d7"
}

.sym-atm:before {
  content: "\f1d8"
}

.sym-atom-s:before {
  content: "\f1d9"
}

.sym-atom:before {
  content: "\f1da"
}

.sym-atp-s:before {
  content: "\f1db"
}

.sym-atp:before {
  content: "\f1dc"
}

.sym-auction-s:before {
  content: "\f1dd"
}

.sym-auction:before {
  content: "\f1de"
}

.sym-aud-s:before {
  content: "\f1df"
}

.sym-aud:before {
  content: "\f1e0"
}

.sym-audio-s:before {
  content: "\f1e1"
}

.sym-audio:before {
  content: "\f1e2"
}

.sym-aup-s:before {
  content: "\f1e3"
}

.sym-aup:before {
  content: "\f1e4"
}

.sym-auto-s:before {
  content: "\f1e5"
}

.sym-auto:before {
  content: "\f1e6"
}

.sym-ava-s:before {
  content: "\f1e7"
}

.sym-ava:before {
  content: "\f1e8"
}

.sym-avax-s:before {
  content: "\f1e9"
}

.sym-avax:before {
  content: "\f1ea"
}

.sym-avt-s:before {
  content: "\f1eb"
}

.sym-avt:before {
  content: "\f1ec"
}

.sym-axp-s:before {
  content: "\f1ed"
}

.sym-axp:before {
  content: "\f1ee"
}

.sym-axs-s:before {
  content: "\f1ef"
}

.sym-axs:before {
  content: "\f1f0"
}

.sym-b:before {
  content: "\f1f1"
}

.sym-b2g-s:before {
  content: "\f1f2"
}

.sym-b2g:before {
  content: "\f1f3"
}

.sym-bab-s:before {
  content: "\f1f4"
}

.sym-bab:before {
  content: "\f1f5"
}

.sym-badger-s:before {
  content: "\f1f6"
}

.sym-badger:before {
  content: "\f1f7"
}

.sym-bake-s:before {
  content: "\f1f8"
}

.sym-bake:before {
  content: "\f1f9"
}

.sym-bal-s:before {
  content: "\f1fa"
}

.sym-bal:before {
  content: "\f1fb"
}

.sym-banca-s:before {
  content: "\f1fc"
}

.sym-banca:before {
  content: "\f1fd"
}

.sym-band-s:before {
  content: "\f1fe"
}

.sym-band:before {
  content: "\f1ff"
}

.sym-bat-s:before {
  content: "\f200"
}

.sym-bat:before {
  content: "\f201"
}

.sym-bay-s:before {
  content: "\f202"
}

.sym-bay:before {
  content: "\f203"
}

.sym-bbc-s:before {
  content: "\f204"
}

.sym-bbc:before {
  content: "\f205"
}

.sym-bcc-s:before {
  content: "\f206"
}

.sym-bcc:before {
  content: "\f207"
}

.sym-bcd-s:before {
  content: "\f208"
}

.sym-bcd:before {
  content: "\f209"
}

.sym-bch-s:before {
  content: "\f20a"
}

.sym-bch:before {
  content: "\f20b"
}

.sym-bci-s:before {
  content: "\f20c"
}

.sym-bci:before {
  content: "\f20d"
}

.sym-bcn-s:before {
  content: "\f20e"
}

.sym-bcn:before {
  content: "\f20f"
}

.sym-bcpt-s:before {
  content: "\f210"
}

.sym-bcpt:before {
  content: "\f211"
}

.sym-bcu-s:before {
  content: "\f212"
}

.sym-bcu:before {
  content: "\f213"
}

.sym-bcv-s:before {
  content: "\f214"
}

.sym-bcv:before {
  content: "\f215"
}

.sym-bcy-s:before {
  content: "\f216"
}

.sym-bcy:before {
  content: "\f217"
}

.sym-bdg-s:before {
  content: "\f218"
}

.sym-bdg:before {
  content: "\f219"
}

.sym-beam-s:before {
  content: "\f21a"
}

.sym-beam:before {
  content: "\f21b"
}

.sym-beet-s:before {
  content: "\f21c"
}

.sym-beet:before {
  content: "\f21d"
}

.sym-bel-s:before {
  content: "\f21e"
}

.sym-bel:before {
  content: "\f21f"
}

.sym-bela-s:before {
  content: "\f220"
}

.sym-bela:before {
  content: "\f221"
}

.sym-berry-s:before {
  content: "\f222"
}

.sym-berry:before {
  content: "\f223"
}

.sym-betr-s:before {
  content: "\f224"
}

.sym-betr:before {
  content: "\f225"
}

.sym-bez-s:before {
  content: "\f226"
}

.sym-bez:before {
  content: "\f227"
}

.sym-bft-s:before {
  content: "\f228"
}

.sym-bft:before {
  content: "\f229"
}

.sym-bfx-s:before {
  content: "\f22a"
}

.sym-bfx:before {
  content: "\f22b"
}

.sym-bhd-s:before {
  content: "\f22c"
}

.sym-bhd:before {
  content: "\f22d"
}

.sym-bht-s:before {
  content: "\f22e"
}

.sym-bht:before {
  content: "\f22f"
}

.sym-bitb-s:before {
  content: "\f230"
}

.sym-bitb:before {
  content: "\f231"
}

.sym-bix-s:before {
  content: "\f232"
}

.sym-bix:before {
  content: "\f233"
}

.sym-bk-s:before {
  content: "\f234"
}

.sym-bk:before {
  content: "\f235"
}

.sym-bkx-s:before {
  content: "\f236"
}

.sym-bkx:before {
  content: "\f237"
}

.sym-blk-s:before {
  content: "\f238"
}

.sym-blk:before {
  content: "\f239"
}

.sym-block-s:before {
  content: "\f23a"
}

.sym-block:before {
  content: "\f23b"
}

.sym-blt-s:before {
  content: "\f23c"
}

.sym-blt:before {
  content: "\f23d"
}

.sym-blz-s:before {
  content: "\f23e"
}

.sym-blz:before {
  content: "\f23f"
}

.sym-bmc-s:before {
  content: "\f240"
}

.sym-bmc:before {
  content: "\f241"
}

.sym-bnb-s:before {
  content: "\f242"
}

.sym-bnb:before {
  content: "\f243"
}

.sym-bnc-s:before {
  content: "\f244"
}

.sym-bnc:before {
  content: "\f245"
}

.sym-bnk-s:before {
  content: "\f246"
}

.sym-bnk:before {
  content: "\f247"
}

.sym-bnt-s:before {
  content: "\f248"
}

.sym-bnt:before {
  content: "\f249"
}

.sym-bo-s:before {
  content: "\f24a"
}

.sym-bo:before {
  content: "\f24b"
}

.sym-bond-s:before {
  content: "\f24c"
}

.sym-bond:before {
  content: "\f24d"
}

.sym-bor-s:before {
  content: "\f24e"
}

.sym-bor:before {
  content: "\f24f"
}

.sym-bora-s:before {
  content: "\f250"
}

.sym-bora:before {
  content: "\f251"
}

.sym-bos-s:before {
  content: "\f252"
}

.sym-bos:before {
  content: "\f253"
}

.sym-box-s:before {
  content: "\f254"
}

.sym-box:before {
  content: "\f255"
}

.sym-brd-s:before {
  content: "\f256"
}

.sym-brd:before {
  content: "\f257"
}

.sym-brg-s:before {
  content: "\f258"
}

.sym-brg:before {
  content: "\f259"
}

.sym-brick-s:before {
  content: "\f25a"
}

.sym-brick:before {
  content: "\f25b"
}

.sym-bsd-s:before {
  content: "\f25c"
}

.sym-bsd:before {
  content: "\f25d"
}

.sym-bsv-s:before {
  content: "\f25e"
}

.sym-bsv:before {
  content: "\f25f"
}

.sym-bsx-s:before {
  content: "\f260"
}

.sym-bsx:before {
  content: "\f261"
}

.sym-bt1-s:before {
  content: "\f262"
}

.sym-bt1:before {
  content: "\f263"
}

.sym-bt2-s:before {
  content: "\f264"
}

.sym-bt2:before {
  content: "\f265"
}

.sym-btc-s:before {
  content: "\f266"
}

.sym-btc:before {
  content: "\f267"
}

.sym-btcd-s:before {
  content: "\f268"
}

.sym-btcd:before {
  content: "\f269"
}

.sym-btcfx-s:before {
  content: "\f26a"
}

.sym-btcfx:before {
  content: "\f26b"
}

.sym-btcp-s:before {
  content: "\f26c"
}

.sym-btcp:before {
  content: "\f26d"
}

.sym-btg-s:before {
  content: "\f26e"
}

.sym-btg:before {
  content: "\f26f"
}

.sym-btm-s:before {
  content: "\f270"
}

.sym-btm:before {
  content: "\f271"
}

.sym-btn-s:before {
  content: "\f272"
}

.sym-btn:before {
  content: "\f273"
}

.sym-bto-s:before {
  content: "\f274"
}

.sym-bto:before {
  content: "\f275"
}

.sym-bts-s:before {
  content: "\f276"
}

.sym-bts:before {
  content: "\f277"
}

.sym-btt-s:before {
  content: "\f278"
}

.sym-btt:before {
  content: "\f279"
}

.sym-btu-s:before {
  content: "\f27a"
}

.sym-btu:before {
  content: "\f27b"
}

.sym-btx-s:before {
  content: "\f27c"
}

.sym-btx:before {
  content: "\f27d"
}

.sym-burger-s:before {
  content: "\f27e"
}

.sym-burger:before {
  content: "\f27f"
}

.sym-burst-s:before {
  content: "\f280"
}

.sym-burst:before {
  content: "\f281"
}

.sym-bus-s:before {
  content: "\f282"
}

.sym-bus:before {
  content: "\f283"
}

.sym-busd-s:before {
  content: "\f284"
}

.sym-busd:before {
  content: "\f285"
}

.sym-bwx-s:before {
  content: "\f286"
}

.sym-bwx:before {
  content: "\f287"
}

.sym-bz-s:before {
  content: "\f288"
}

.sym-bz:before {
  content: "\f289"
}

.sym-bzrx-s:before {
  content: "\f28a"
}

.sym-bzrx:before {
  content: "\f28b"
}

.sym-c-s:before {
  content: "\f28c"
}

.sym-c:before {
  content: "\f28d"
}

.sym-c20-s:before {
  content: "\f28e"
}

.sym-c20:before {
  content: "\f28f"
}

.sym-cad-s:before {
  content: "\f290"
}

.sym-cad:before {
  content: "\f291"
}

.sym-cake-s:before {
  content: "\f292"
}

.sym-cake:before {
  content: "\f293"
}

.sym-cas-s:before {
  content: "\f294"
}

.sym-cas:before {
  content: "\f295"
}

.sym-cat-s:before {
  content: "\f296"
}

.sym-cat:before {
  content: "\f297"
}

.sym-cbc-s:before {
  content: "\f298"
}

.sym-cbc:before {
  content: "\f299"
}

.sym-cbt-s:before {
  content: "\f29a"
}

.sym-cbt:before {
  content: "\f29b"
}

.sym-cdt-s:before {
  content: "\f29c"
}

.sym-cdt:before {
  content: "\f29d"
}

.sym-cel-s:before {
  content: "\f29e"
}

.sym-cel:before {
  content: "\f29f"
}

.sym-celo-s:before {
  content: "\f2a0"
}

.sym-celo:before {
  content: "\f2a1"
}

.sym-celr-s:before {
  content: "\f2a2"
}

.sym-celr:before {
  content: "\f2a3"
}

.sym-cennz-s:before {
  content: "\f2a4"
}

.sym-cennz:before {
  content: "\f2a5"
}

.sym-cfg-s:before {
  content: "\f2a6"
}

.sym-cfg:before {
  content: "\f2a7"
}

.sym-cfi-s:before {
  content: "\f2a8"
}

.sym-cfi:before {
  content: "\f2a9"
}

.sym-cfx-s:before {
  content: "\f2aa"
}

.sym-cfx:before {
  content: "\f2ab"
}

.sym-cgt-s:before {
  content: "\f2ac"
}

.sym-cgt:before {
  content: "\f2ad"
}

.sym-chat-s:before {
  content: "\f2ae"
}

.sym-chat:before {
  content: "\f2af"
}

.sym-chf-s:before {
  content: "\f2b0"
}

.sym-chf:before {
  content: "\f2b1"
}

.sym-chp-s:before {
  content: "\f2b2"
}

.sym-chp:before {
  content: "\f2b3"
}

.sym-chr-s:before {
  content: "\f2b4"
}

.sym-chr:before {
  content: "\f2b5"
}

.sym-chsb-s:before {
  content: "\f2b6"
}

.sym-chsb:before {
  content: "\f2b7"
}

.sym-chx-s:before {
  content: "\f2b8"
}

.sym-chx:before {
  content: "\f2b9"
}

.sym-chz-s:before {
  content: "\f2ba"
}

.sym-chz:before {
  content: "\f2bb"
}

.sym-ckb-s:before {
  content: "\f2bc"
}

.sym-ckb:before {
  content: "\f2bd"
}

.sym-cl-s:before {
  content: "\f2be"
}

.sym-cl:before {
  content: "\f2bf"
}

.sym-clam-s:before {
  content: "\f2c0"
}

.sym-clam:before {
  content: "\f2c1"
}

.sym-cln-s:before {
  content: "\f2c2"
}

.sym-cln:before {
  content: "\f2c3"
}

.sym-clo-s:before {
  content: "\f2c4"
}

.sym-clo:before {
  content: "\f2c5"
}

.sym-cloak-s:before {
  content: "\f2c6"
}

.sym-cloak:before {
  content: "\f2c7"
}

.sym-clv-s:before {
  content: "\f2c8"
}

.sym-clv:before {
  content: "\f2c9"
}

.sym-cmct-s:before {
  content: "\f2ca"
}

.sym-cmct:before {
  content: "\f2cb"
}

.sym-cmt-s:before {
  content: "\f2cc"
}

.sym-cmt:before {
  content: "\f2cd"
}

.sym-cnd-s:before {
  content: "\f2ce"
}

.sym-cnd:before {
  content: "\f2cf"
}

.sym-cnn-s:before {
  content: "\f2d0"
}

.sym-cnn:before {
  content: "\f2d1"
}

.sym-cnx-s:before {
  content: "\f2d2"
}

.sym-cnx:before {
  content: "\f2d3"
}

.sym-cny-s:before {
  content: "\f2d4"
}

.sym-cny:before {
  content: "\f2d5"
}

.sym-cob-s:before {
  content: "\f2d6"
}

.sym-cob:before {
  content: "\f2d7"
}

.sym-cocos-s:before {
  content: "\f2d8"
}

.sym-cocos:before {
  content: "\f2d9"
}

.sym-comp-s:before {
  content: "\f2da"
}

.sym-comp:before {
  content: "\f2db"
}

.sym-cos-s:before {
  content: "\f2dc"
}

.sym-cos:before {
  content: "\f2dd"
}

.sym-cosm-s:before {
  content: "\f2de"
}

.sym-cosm:before {
  content: "\f2df"
}

.sym-coss-s:before {
  content: "\f2e0"
}

.sym-coss:before {
  content: "\f2e1"
}

.sym-coti-s:before {
  content: "\f2e2"
}

.sym-coti:before {
  content: "\f2e3"
}

.sym-cov-s:before {
  content: "\f2e4"
}

.sym-cov:before {
  content: "\f2e5"
}

.sym-cova-s:before {
  content: "\f2e6"
}

.sym-cova:before {
  content: "\f2e7"
}

.sym-cpt-s:before {
  content: "\f2e8"
}

.sym-cpt:before {
  content: "\f2e9"
}

.sym-cpx-s:before {
  content: "\f2ea"
}

.sym-cpx:before {
  content: "\f2eb"
}

.sym-cqt-s:before {
  content: "\f2ec"
}

.sym-cqt:before {
  content: "\f2ed"
}

.sym-crc-s:before {
  content: "\f2ee"
}

.sym-crc:before {
  content: "\f2ef"
}

.sym-cre-s:before {
  content: "\f2f0"
}

.sym-cre:before {
  content: "\f2f1"
}

.sym-cream-s:before {
  content: "\f2f2"
}

.sym-cream:before {
  content: "\f2f3"
}

.sym-cring-s:before {
  content: "\f2f4"
}

.sym-cring:before {
  content: "\f2f5"
}

.sym-cro-s:before {
  content: "\f2f6"
}

.sym-cro:before {
  content: "\f2f7"
}

.sym-crpt-s:before {
  content: "\f2f8"
}

.sym-crpt:before {
  content: "\f2f9"
}

.sym-cru-s:before {
  content: "\f2fa"
}

.sym-cru:before {
  content: "\f2fb"
}

.sym-crv-s:before {
  content: "\f2fc"
}

.sym-crv:before {
  content: "\f2fd"
}

.sym-crw-s:before {
  content: "\f2fe"
}

.sym-crw:before {
  content: "\f2ff"
}

.sym-csx-s:before {
  content: "\f300"
}

.sym-csx:before {
  content: "\f301"
}

.sym-ctc-s:before {
  content: "\f302"
}

.sym-ctc:before {
  content: "\f303"
}

.sym-ctk-s:before {
  content: "\f304"
}

.sym-ctk:before {
  content: "\f305"
}

.sym-ctsi-s:before {
  content: "\f306"
}

.sym-ctsi:before {
  content: "\f307"
}

.sym-ctxc-s:before {
  content: "\f308"
}

.sym-ctxc:before {
  content: "\f309"
}

.sym-cur-s:before {
  content: "\f30a"
}

.sym-cur:before {
  content: "\f30b"
}

.sym-cvc-s:before {
  content: "\f30c"
}

.sym-cvc:before {
  content: "\f30d"
}

.sym-cvcoin-s:before {
  content: "\f30e"
}

.sym-cvcoin:before {
  content: "\f30f"
}

.sym-cvnt-s:before {
  content: "\f310"
}

.sym-cvnt:before {
  content: "\f311"
}

.sym-cvp-s:before {
  content: "\f312"
}

.sym-cvp:before {
  content: "\f313"
}

.sym-cvt-s:before {
  content: "\f314"
}

.sym-cvt:before {
  content: "\f315"
}

.sym-cw-s:before {
  content: "\f316"
}

.sym-cw:before {
  content: "\f317"
}

.sym-cyc-s:before {
  content: "\f318"
}

.sym-cyc:before {
  content: "\f319"
}

.sym-dac-s:before {
  content: "\f31a"
}

.sym-dac:before {
  content: "\f31b"
}

.sym-dacs-s:before {
  content: "\f31c"
}

.sym-dacs:before {
  content: "\f31d"
}

.sym-dadi-s:before {
  content: "\f31e"
}

.sym-dadi:before {
  content: "\f31f"
}

.sym-dag-s:before {
  content: "\f320"
}

.sym-dag:before {
  content: "\f321"
}

.sym-dai-s:before {
  content: "\f322"
}

.sym-dai:before {
  content: "\f323"
}

.sym-dao-s:before {
  content: "\f324"
}

.sym-dao:before {
  content: "\f325"
}

.sym-dash-s:before {
  content: "\f326"
}

.sym-dash:before {
  content: "\f327"
}

.sym-dat-s:before {
  content: "\f328"
}

.sym-dat:before {
  content: "\f329"
}

.sym-data-s:before {
  content: "\f32a"
}

.sym-data:before {
  content: "\f32b"
}

.sym-datx-s:before {
  content: "\f32c"
}

.sym-datx:before {
  content: "\f32d"
}

.sym-dbc-s:before {
  content: "\f32e"
}

.sym-dbc:before {
  content: "\f32f"
}

.sym-dbet-s:before {
  content: "\f330"
}

.sym-dbet:before {
  content: "\f331"
}

.sym-dbix-s:before {
  content: "\f332"
}

.sym-dbix:before {
  content: "\f333"
}

.sym-dcn-s:before {
  content: "\f334"
}

.sym-dcn:before {
  content: "\f335"
}

.sym-dcr-s:before {
  content: "\f336"
}

.sym-dcr:before {
  content: "\f337"
}

.sym-dct-s:before {
  content: "\f338"
}

.sym-dct:before {
  content: "\f339"
}

.sym-ddd-s:before {
  content: "\f33a"
}

.sym-ddd:before {
  content: "\f33b"
}

.sym-dego-s:before {
  content: "\f33c"
}

.sym-dego:before {
  content: "\f33d"
}

.sym-dent-s:before {
  content: "\f33e"
}

.sym-dent:before {
  content: "\f33f"
}

.sym-dgb-s:before {
  content: "\f340"
}

.sym-dgb:before {
  content: "\f341"
}

.sym-dgd-s:before {
  content: "\f342"
}

.sym-dgd:before {
  content: "\f343"
}

.sym-dgtx-s:before {
  content: "\f344"
}

.sym-dgtx:before {
  content: "\f345"
}

.sym-dgx-s:before {
  content: "\f346"
}

.sym-dgx:before {
  content: "\f347"
}

.sym-dhx-s:before {
  content: "\f348"
}

.sym-dhx:before {
  content: "\f349"
}

.sym-dia-s:before {
  content: "\f34a"
}

.sym-dia:before {
  content: "\f34b"
}

.sym-dice-s:before {
  content: "\f34c"
}

.sym-dice:before {
  content: "\f34d"
}

.sym-dim-s:before {
  content: "\f34e"
}

.sym-dim:before {
  content: "\f34f"
}

.sym-dlt-s:before {
  content: "\f350"
}

.sym-dlt:before {
  content: "\f351"
}

.sym-dmd-s:before {
  content: "\f352"
}

.sym-dmd:before {
  content: "\f353"
}

.sym-dmt-s:before {
  content: "\f354"
}

.sym-dmt:before {
  content: "\f355"
}

.sym-dnt-s:before {
  content: "\f356"
}

.sym-dnt:before {
  content: "\f357"
}

.sym-dock-s:before {
  content: "\f358"
}

.sym-dock:before {
  content: "\f359"
}

.sym-dodo-s:before {
  content: "\f35a"
}

.sym-dodo:before {
  content: "\f35b"
}

.sym-doge-s:before {
  content: "\f35c"
}

.sym-doge:before {
  content: "\f35d"
}

.sym-dot-s:before {
  content: "\f35e"
}

.sym-dot:before {
  content: "\f35f"
}

.sym-dpy-s:before {
  content: "\f360"
}

.sym-dpy:before {
  content: "\f361"
}

.sym-dream-s:before {
  content: "\f362"
}

.sym-dream:before {
  content: "\f363"
}

.sym-drep-s:before {
  content: "\f364"
}

.sym-drep:before {
  content: "\f365"
}

.sym-drg-s:before {
  content: "\f366"
}

.sym-drg:before {
  content: "\f367"
}

.sym-drgn-s:before {
  content: "\f368"
}

.sym-drgn:before {
  content: "\f369"
}

.sym-drt-s:before {
  content: "\f36a"
}

.sym-drt:before {
  content: "\f36b"
}

.sym-dta-s:before {
  content: "\f36c"
}

.sym-dta:before {
  content: "\f36d"
}

.sym-dtb-s:before {
  content: "\f36e"
}

.sym-dtb:before {
  content: "\f36f"
}

.sym-dtr-s:before {
  content: "\f370"
}

.sym-dtr:before {
  content: "\f371"
}

.sym-dusk-s:before {
  content: "\f372"
}

.sym-dusk:before {
  content: "\f373"
}

.sym-dx-s:before {
  content: "\f374"
}

.sym-dx:before {
  content: "\f375"
}

.sym-dydx-s:before {
  content: "\f376"
}

.sym-dydx:before {
  content: "\f377"
}

.sym-dyn-s:before {
  content: "\f378"
}

.sym-dyn:before {
  content: "\f379"
}

.sym-easy:before {
  content: "\f37a"
}

.sym-ecom-s:before {
  content: "\f37b"
}

.sym-ecom:before {
  content: "\f37c"
}

.sym-edc-s:before {
  content: "\f37d"
}

.sym-edc:before {
  content: "\f37e"
}

.sym-edg-s:before {
  content: "\f37f"
}

.sym-edg:before {
  content: "\f380"
}

.sym-edo-s:before {
  content: "\f381"
}

.sym-edo:before {
  content: "\f382"
}

.sym-edp-s:before {
  content: "\f383"
}

.sym-edp:before {
  content: "\f384"
}

.sym-edr-s:before {
  content: "\f385"
}

.sym-edr:before {
  content: "\f386"
}

.sym-egld-s:before {
  content: "\f387"
}

.sym-egld:before {
  content: "\f388"
}

.sym-egt-s:before {
  content: "\f389"
}

.sym-egt:before {
  content: "\f38a"
}

.sym-ehr-s:before {
  content: "\f38b"
}

.sym-ehr:before {
  content: "\f38c"
}

.sym-eko-s:before {
  content: "\f38d"
}

.sym-eko:before {
  content: "\f38e"
}

.sym-ekt-s:before {
  content: "\f38f"
}

.sym-ekt:before {
  content: "\f390"
}

.sym-ela-s:before {
  content: "\f391"
}

.sym-ela:before {
  content: "\f392"
}

.sym-elec-s:before {
  content: "\f393"
}

.sym-elec:before {
  content: "\f394"
}

.sym-elf-s:before {
  content: "\f395"
}

.sym-elf:before {
  content: "\f396"
}

.sym-em-s:before {
  content: "\f397"
}

.sym-em:before {
  content: "\f398"
}

.sym-emc-s:before {
  content: "\f399"
}

.sym-emc:before {
  content: "\f39a"
}

.sym-emc2-s:before {
  content: "\f39b"
}

.sym-emc2:before {
  content: "\f39c"
}

.sym-eng-s:before {
  content: "\f39d"
}

.sym-eng:before {
  content: "\f39e"
}

.sym-enj-s:before {
  content: "\f39f"
}

.sym-enj:before {
  content: "\f3a0"
}

.sym-eos-s:before {
  content: "\f3a1"
}

.sym-eos:before {
  content: "\f3a2"
}

.sym-eosdac-s:before {
  content: "\f3a3"
}

.sym-eosdac:before {
  content: "\f3a4"
}

.sym-eq-s:before {
  content: "\f3a5"
}

.sym-eq:before {
  content: "\f3a6"
}

.sym-erd-s:before {
  content: "\f3a7"
}

.sym-erd:before {
  content: "\f3a8"
}

.sym-ern-s:before {
  content: "\f3a9"
}

.sym-ern:before {
  content: "\f3aa"
}

.sym-es-s:before {
  content: "\f3ab"
}

.sym-es:before {
  content: "\f3ac"
}

.sym-esd-s:before {
  content: "\f3ad"
}

.sym-esd:before {
  content: "\f3ae"
}

.sym-etc-s:before {
  content: "\f3af"
}

.sym-etc:before {
  content: "\f3b0"
}

.sym-eth-s:before {
  content: "\f3b1"
}

.sym-eth:before {
  content: "\f3b2"
}

.sym-ethup-s:before {
  content: "\f3b3"
}

.sym-ethup:before {
  content: "\f3b4"
}

.sym-etn-s:before {
  content: "\f3b5"
}

.sym-etn:before {
  content: "\f3b6"
}

.sym-etp-s:before {
  content: "\f3b7"
}

.sym-etp:before {
  content: "\f3b8"
}

.sym-eur-s:before {
  content: "\f3b9"
}

.sym-eur:before {
  content: "\f3ba"
}

.sym-eurs-s:before {
  content: "\f3bb"
}

.sym-eurs:before {
  content: "\f3bc"
}

.sym-eurt-s:before {
  content: "\f3bd"
}

.sym-eurt:before {
  content: "\f3be"
}

.sym-evn-s:before {
  content: "\f3bf"
}

.sym-evn:before {
  content: "\f3c0"
}

.sym-evx-s:before {
  content: "\f3c1"
}

.sym-evx:before {
  content: "\f3c2"
}

.sym-ewt-s:before {
  content: "\f3c3"
}

.sym-ewt:before {
  content: "\f3c4"
}

.sym-exp-s:before {
  content: "\f3c5"
}

.sym-exp:before {
  content: "\f3c6"
}

.sym-exrd-s:before {
  content: "\f3c7"
}

.sym-exrd:before {
  content: "\f3c8"
}

.sym-exy-s:before {
  content: "\f3c9"
}

.sym-exy:before {
  content: "\f3ca"
}

.sym-fair-s:before {
  content: "\f3cb"
}

.sym-fair:before {
  content: "\f3cc"
}

.sym-fct-s:before {
  content: "\f3cd"
}

.sym-fct:before {
  content: "\f3ce"
}

.sym-fdz-s:before {
  content: "\f3cf"
}

.sym-fdz:before {
  content: "\f3d0"
}

.sym-fee-s:before {
  content: "\f3d1"
}

.sym-fee:before {
  content: "\f3d2"
}

.sym-fet-s:before {
  content: "\f3d3"
}

.sym-fet:before {
  content: "\f3d4"
}

.sym-fil-s:before {
  content: "\f3d5"
}

.sym-fil:before {
  content: "\f3d6"
}

.sym-fio-s:before {
  content: "\f3d7"
}

.sym-fio:before {
  content: "\f3d8"
}

.sym-firo-s:before {
  content: "\f3d9"
}

.sym-firo:before {
  content: "\f3da"
}

.sym-fis-s:before {
  content: "\f3db"
}

.sym-fis:before {
  content: "\f3dc"
}

.sym-fldc-s:before {
  content: "\f3dd"
}

.sym-fldc:before {
  content: "\f3de"
}

.sym-flo-s:before {
  content: "\f3df"
}

.sym-flo:before {
  content: "\f3e0"
}

.sym-flow-s:before {
  content: "\f3e1"
}

.sym-flow:before {
  content: "\f3e2"
}

.sym-flr-s:before {
  content: "\f3e3"
}

.sym-flr:before {
  content: "\f3e4"
}

.sym-fluz-s:before {
  content: "\f3e5"
}

.sym-fluz:before {
  content: "\f3e6"
}

.sym-fnb-s:before {
  content: "\f3e7"
}

.sym-fnb:before {
  content: "\f3e8"
}

.sym-foam-s:before {
  content: "\f3e9"
}

.sym-foam:before {
  content: "\f3ea"
}

.sym-for-s:before {
  content: "\f3eb"
}

.sym-for:before {
  content: "\f3ec"
}

.sym-fota-s:before {
  content: "\f3ed"
}

.sym-fota:before {
  content: "\f3ee"
}

.sym-frax-s:before {
  content: "\f3ef"
}

.sym-frax:before {
  content: "\f3f0"
}

.sym-front-s:before {
  content: "\f3f1"
}

.sym-front:before {
  content: "\f3f2"
}

.sym-fsn-s:before {
  content: "\f3f3"
}

.sym-fsn:before {
  content: "\f3f4"
}

.sym-ftc-s:before {
  content: "\f3f5"
}

.sym-ftc:before {
  content: "\f3f6"
}

.sym-fti-s:before {
  content: "\f3f7"
}

.sym-fti:before {
  content: "\f3f8"
}

.sym-ftm-s:before {
  content: "\f3f9"
}

.sym-ftm:before {
  content: "\f3fa"
}

.sym-ftt-s:before {
  content: "\f3fb"
}

.sym-ftt:before {
  content: "\f3fc"
}

.sym-ftx-s:before {
  content: "\f3fd"
}

.sym-ftx:before {
  content: "\f3fe"
}

.sym-fuel-s:before {
  content: "\f3ff"
}

.sym-fuel:before {
  content: "\f400"
}

.sym-fun-s:before {
  content: "\f401"
}

.sym-fun:before {
  content: "\f402"
}

.sym-fx-s:before {
  content: "\f403"
}

.sym-fx:before {
  content: "\f404"
}

.sym-fxc-s:before {
  content: "\f405"
}

.sym-fxc:before {
  content: "\f406"
}

.sym-fxs-s:before {
  content: "\f407"
}

.sym-fxs:before {
  content: "\f408"
}

.sym-fxt-s:before {
  content: "\f409"
}

.sym-fxt:before {
  content: "\f40a"
}

.sym-game-s:before {
  content: "\f40b"
}

.sym-game:before {
  content: "\f40c"
}

.sym-gard-s:before {
  content: "\f40d"
}

.sym-gard:before {
  content: "\f40e"
}

.sym-gas-s:before {
  content: "\f40f"
}

.sym-gas:before {
  content: "\f410"
}

.sym-gbc-s:before {
  content: "\f411"
}

.sym-gbc:before {
  content: "\f412"
}

.sym-gbp-s:before {
  content: "\f413"
}

.sym-gbp:before {
  content: "\f414"
}

.sym-gbx-s:before {
  content: "\f415"
}

.sym-gbx:before {
  content: "\f416"
}

.sym-gbyte-s:before {
  content: "\f417"
}

.sym-gbyte:before {
  content: "\f418"
}

.sym-gc-s:before {
  content: "\f419"
}

.sym-gc:before {
  content: "\f41a"
}

.sym-gcc-s:before {
  content: "\f41b"
}

.sym-gcc:before {
  content: "\f41c"
}

.sym-ge-s:before {
  content: "\f41d"
}

.sym-ge:before {
  content: "\f41e"
}

.sym-gen-s:before {
  content: "\f41f"
}

.sym-gen:before {
  content: "\f420"
}

.sym-gens-s:before {
  content: "\f421"
}

.sym-gens:before {
  content: "\f422"
}

.sym-get-s:before {
  content: "\f423"
}

.sym-get:before {
  content: "\f424"
}

.sym-ghst-s:before {
  content: "\f425"
}

.sym-ghst:before {
  content: "\f426"
}

.sym-glc-s:before {
  content: "\f427"
}

.sym-glc:before {
  content: "\f428"
}

.sym-gld-s:before {
  content: "\f429"
}

.sym-gld:before {
  content: "\f42a"
}

.sym-glm-s:before {
  content: "\f42b"
}

.sym-glm:before {
  content: "\f42c"
}

.sym-glmr-s:before {
  content: "\f42d"
}

.sym-glmr:before {
  content: "\f42e"
}

.sym-gmat-s:before {
  content: "\f42f"
}

.sym-gmat:before {
  content: "\f430"
}

.sym-gno-s:before {
  content: "\f431"
}

.sym-gno:before {
  content: "\f432"
}

.sym-gnt-s:before {
  content: "\f433"
}

.sym-gnt:before {
  content: "\f434"
}

.sym-gnx-s:before {
  content: "\f435"
}

.sym-gnx:before {
  content: "\f436"
}

.sym-go-s:before {
  content: "\f437"
}

.sym-go:before {
  content: "\f438"
}

.sym-got-s:before {
  content: "\f439"
}

.sym-got:before {
  content: "\f43a"
}

.sym-grc-s:before {
  content: "\f43b"
}

.sym-grc:before {
  content: "\f43c"
}

.sym-grin-s:before {
  content: "\f43d"
}

.sym-grin:before {
  content: "\f43e"
}

.sym-grs-s:before {
  content: "\f43f"
}

.sym-grs:before {
  content: "\f440"
}

.sym-grt-s:before {
  content: "\f441"
}

.sym-grt:before {
  content: "\f442"
}

.sym-gsc-s:before {
  content: "\f443"
}

.sym-gsc:before {
  content: "\f444"
}

.sym-gt-s:before {
  content: "\f445"
}

.sym-gt:before {
  content: "\f446"
}

.sym-gtc-s:before {
  content: "\f447"
}

.sym-gtc:before {
  content: "\f448"
}

.sym-gto-s:before {
  content: "\f449"
}

.sym-gto:before {
  content: "\f44a"
}

.sym-gup-s:before {
  content: "\f44b"
}

.sym-gup:before {
  content: "\f44c"
}

.sym-gusd-s:before {
  content: "\f44d"
}

.sym-gusd:before {
  content: "\f44e"
}

.sym-gvt-s:before {
  content: "\f44f"
}

.sym-gvt:before {
  content: "\f450"
}

.sym-gxc-s:before {
  content: "\f451"
}

.sym-gxc:before {
  content: "\f452"
}

.sym-gxs-s:before {
  content: "\f453"
}

.sym-gxs:before {
  content: "\f454"
}

.sym-hard-s:before {
  content: "\f455"
}

.sym-hard:before {
  content: "\f456"
}

.sym-hbar-s:before {
  content: "\f457"
}

.sym-hbar:before {
  content: "\f458"
}

.sym-hc-s:before {
  content: "\f459"
}

.sym-hc:before {
  content: "\f45a"
}

.sym-hdx-s:before {
  content: "\f45b"
}

.sym-hdx:before {
  content: "\f45c"
}

.sym-hedg-s:before {
  content: "\f45d"
}

.sym-hedg:before {
  content: "\f45e"
}

.sym-hex-s:before {
  content: "\f45f"
}

.sym-hex:before {
  content: "\f460"
}

.sym-hg-s:before {
  content: "\f461"
}

.sym-hg:before {
  content: "\f462"
}

.sym-hgs-s:before {
  content: "\f463"
}

.sym-hgs:before {
  content: "\f464"
}

.sym-hh-s:before {
  content: "\f465"
}

.sym-hh:before {
  content: "\f466"
}

.sym-hit-s:before {
  content: "\f467"
}

.sym-hit:before {
  content: "\f468"
}

.sym-hive-s:before {
  content: "\f469"
}

.sym-hive:before {
  content: "\f46a"
}

.sym-hkd-s:before {
  content: "\f46b"
}

.sym-hkd:before {
  content: "\f46c"
}

.sym-hmq-s:before {
  content: "\f46d"
}

.sym-hmq:before {
  content: "\f46e"
}

.sym-hns-s:before {
  content: "\f46f"
}

.sym-hns:before {
  content: "\f470"
}

.sym-ho-s:before {
  content: "\f471"
}

.sym-ho:before {
  content: "\f472"
}

.sym-hot-s:before {
  content: "\f473"
}

.sym-hot:before {
  content: "\f474"
}

.sym-hp-s:before {
  content: "\f475"
}

.sym-hp:before {
  content: "\f476"
}

.sym-hpb-s:before {
  content: "\f477"
}

.sym-hpb:before {
  content: "\f478"
}

.sym-hpc-s:before {
  content: "\f479"
}

.sym-hpc:before {
  content: "\f47a"
}

.sym-hpt-s:before {
  content: "\f47b"
}

.sym-hpt:before {
  content: "\f47c"
}

.sym-hrc-s:before {
  content: "\f47d"
}

.sym-hrc:before {
  content: "\f47e"
}

.sym-hsc-s:before {
  content: "\f47f"
}

.sym-hsc:before {
  content: "\f480"
}

.sym-hsr-s:before {
  content: "\f481"
}

.sym-hsr:before {
  content: "\f482"
}

.sym-hst-s:before {
  content: "\f483"
}

.sym-hst:before {
  content: "\f484"
}

.sym-ht-s:before {
  content: "\f485"
}

.sym-ht:before {
  content: "\f486"
}

.sym-html-s:before {
  content: "\f487"
}

.sym-html:before {
  content: "\f488"
}

.sym-htt-s:before {
  content: "\f489"
}

.sym-htt:before {
  content: "\f48a"
}

.sym-huc-s:before {
  content: "\f48b"
}

.sym-huc:before {
  content: "\f48c"
}

.sym-hvn-s:before {
  content: "\f48d"
}

.sym-hvn:before {
  content: "\f48e"
}

.sym-hxro-s:before {
  content: "\f48f"
}

.sym-hxro:before {
  content: "\f490"
}

.sym-hyc-s:before {
  content: "\f491"
}

.sym-hyc:before {
  content: "\f492"
}

.sym-hydra-s:before {
  content: "\f493"
}

.sym-hydra:before {
  content: "\f494"
}

.sym-hydro-s:before {
  content: "\f495"
}

.sym-hydro:before {
  content: "\f496"
}

.sym-icn-s:before {
  content: "\f497"
}

.sym-icn:before {
  content: "\f498"
}

.sym-icos-s:before {
  content: "\f499"
}

.sym-icos:before {
  content: "\f49a"
}

.sym-icp-s:before {
  content: "\f49b"
}

.sym-icp:before {
  content: "\f49c"
}

.sym-icx-s:before {
  content: "\f49d"
}

.sym-icx:before {
  content: "\f49e"
}

.sym-idex-s:before {
  content: "\f49f"
}

.sym-idex:before {
  content: "\f4a0"
}

.sym-idh-s:before {
  content: "\f4a1"
}

.sym-idh:before {
  content: "\f4a2"
}

.sym-idr-s:before {
  content: "\f4a3"
}

.sym-idr:before {
  content: "\f4a4"
}

.sym-ift-s:before {
  content: "\f4a5"
}

.sym-ift:before {
  content: "\f4a6"
}

.sym-ignis-s:before {
  content: "\f4a7"
}

.sym-ignis:before {
  content: "\f4a8"
}

.sym-ihf-s:before {
  content: "\f4a9"
}

.sym-ihf:before {
  content: "\f4aa"
}

.sym-iht-s:before {
  content: "\f4ab"
}

.sym-iht:before {
  content: "\f4ac"
}

.sym-ilc-s:before {
  content: "\f4ad"
}

.sym-ilc:before {
  content: "\f4ae"
}

.sym-incnt-s:before {
  content: "\f4af"
}

.sym-incnt:before {
  content: "\f4b0"
}

.sym-ind-s:before {
  content: "\f4b1"
}

.sym-ind:before {
  content: "\f4b2"
}

.sym-inj-s:before {
  content: "\f4b3"
}

.sym-inj:before {
  content: "\f4b4"
}

.sym-ink-s:before {
  content: "\f4b5"
}

.sym-ink:before {
  content: "\f4b6"
}

.sym-inr-s:before {
  content: "\f4b7"
}

.sym-inr:before {
  content: "\f4b8"
}

.sym-ins-s:before {
  content: "\f4b9"
}

.sym-ins:before {
  content: "\f4ba"
}

.sym-int-s:before {
  content: "\f4bb"
}

.sym-int:before {
  content: "\f4bc"
}

.sym-ioc-s:before {
  content: "\f4bd"
}

.sym-ioc:before {
  content: "\f4be"
}

.sym-ion-s:before {
  content: "\f4bf"
}

.sym-ion:before {
  content: "\f4c0"
}

.sym-iost-s:before {
  content: "\f4c1"
}

.sym-iost:before {
  content: "\f4c2"
}

.sym-iot-s:before {
  content: "\f4c3"
}

.sym-iot:before {
  content: "\f4c4"
}

.sym-iotx-s:before {
  content: "\f4c5"
}

.sym-iotx:before {
  content: "\f4c6"
}

.sym-iq-s:before {
  content: "\f4c7"
}

.sym-iq:before {
  content: "\f4c8"
}

.sym-iris-s:before {
  content: "\f4c9"
}

.sym-iris:before {
  content: "\f4ca"
}

.sym-itc-s:before {
  content: "\f4cb"
}

.sym-itc:before {
  content: "\f4cc"
}

.sym-ivy-s:before {
  content: "\f4cd"
}

.sym-ivy:before {
  content: "\f4ce"
}

.sym-ixt-s:before {
  content: "\f4cf"
}

.sym-ixt:before {
  content: "\f4d0"
}

.sym-jnt-s:before {
  content: "\f4d1"
}

.sym-jnt:before {
  content: "\f4d2"
}

.sym-jpy-s:before {
  content: "\f4d3"
}

.sym-jpy:before {
  content: "\f4d4"
}

.sym-jst-s:before {
  content: "\f4d5"
}

.sym-jst:before {
  content: "\f4d6"
}

.sym-juv-s:before {
  content: "\f4d7"
}

.sym-juv:before {
  content: "\f4d8"
}

.sym-kan-s:before {
  content: "\f4d9"
}

.sym-kan:before {
  content: "\f4da"
}

.sym-kar-s:before {
  content: "\f4db"
}

.sym-kar:before {
  content: "\f4dc"
}

.sym-kava-s:before {
  content: "\f4dd"
}

.sym-kava:before {
  content: "\f4de"
}

.sym-kbc-s:before {
  content: "\f4df"
}

.sym-kbc:before {
  content: "\f4e0"
}

.sym-kcash-s:before {
  content: "\f4e1"
}

.sym-kcash:before {
  content: "\f4e2"
}

.sym-keep-s:before {
  content: "\f4e3"
}

.sym-keep:before {
  content: "\f4e4"
}

.sym-key-s:before {
  content: "\f4e5"
}

.sym-key:before {
  content: "\f4e6"
}

.sym-kick-s:before {
  content: "\f4e7"
}

.sym-kick:before {
  content: "\f4e8"
}

.sym-kilt-s:before {
  content: "\f4e9"
}

.sym-kilt:before {
  content: "\f4ea"
}

.sym-kin-s:before {
  content: "\f4eb"
}

.sym-kin:before {
  content: "\f4ec"
}

.sym-kint-s:before {
  content: "\f4ed"
}

.sym-kint:before {
  content: "\f4ee"
}

.sym-kma-s:before {
  content: "\f4ef"
}

.sym-kma:before {
  content: "\f4f0"
}

.sym-kmd-s:before {
  content: "\f4f1"
}

.sym-kmd:before {
  content: "\f4f2"
}

.sym-knc-s:before {
  content: "\f4f3"
}

.sym-knc:before {
  content: "\f4f4"
}

.sym-kore-s:before {
  content: "\f4f5"
}

.sym-kore:before {
  content: "\f4f6"
}

.sym-krm-s:before {
  content: "\f4f7"
}

.sym-krm:before {
  content: "\f4f8"
}

.sym-krw-s:before {
  content: "\f4f9"
}

.sym-krw:before {
  content: "\f4fa"
}

.sym-ksm-s:before {
  content: "\f4fb"
}

.sym-ksm:before {
  content: "\f4fc"
}

.sym-ksx-s:before {
  content: "\f4fd"
}

.sym-ksx:before {
  content: "\f4fe"
}

.sym-kyl-s:before {
  content: "\f4ff"
}

.sym-kyl:before {
  content: "\f500"
}

.sym-la-s:before {
  content: "\f501"
}

.sym-la:before {
  content: "\f502"
}

.sym-lak-s:before {
  content: "\f503"
}

.sym-lak:before {
  content: "\f504"
}

.sym-lamb-s:before {
  content: "\f505"
}

.sym-lamb:before {
  content: "\f506"
}

.sym-latx-s:before {
  content: "\f507"
}

.sym-latx:before {
  content: "\f508"
}

.sym-lba-s:before {
  content: "\f509"
}

.sym-lba:before {
  content: "\f50a"
}

.sym-lbc-s:before {
  content: "\f50b"
}

.sym-lbc:before {
  content: "\f50c"
}

.sym-lcc-s:before {
  content: "\f50d"
}

.sym-lcc:before {
  content: "\f50e"
}

.sym-lend-s:before {
  content: "\f50f"
}

.sym-lend:before {
  content: "\f510"
}

.sym-leo-s:before {
  content: "\f511"
}

.sym-leo:before {
  content: "\f512"
}

.sym-leoc-s:before {
  content: "\f513"
}

.sym-leoc:before {
  content: "\f514"
}

.sym-let-s:before {
  content: "\f515"
}

.sym-let:before {
  content: "\f516"
}

.sym-life-s:before {
  content: "\f517"
}

.sym-life:before {
  content: "\f518"
}

.sym-link-s:before {
  content: "\f519"
}

.sym-link:before {
  content: "\f51a"
}

.sym-lit-s:before {
  content: "\f51b"
}

.sym-lit:before {
  content: "\f51c"
}

.sym-lmc-s:before {
  content: "\f51d"
}

.sym-lmc:before {
  content: "\f51e"
}

.sym-lml-s:before {
  content: "\f51f"
}

.sym-lml:before {
  content: "\f520"
}

.sym-lnc-s:before {
  content: "\f521"
}

.sym-lnc:before {
  content: "\f522"
}

.sym-lnd-s:before {
  content: "\f523"
}

.sym-lnd:before {
  content: "\f524"
}

.sym-loc-s:before {
  content: "\f525"
}

.sym-loc:before {
  content: "\f526"
}

.sym-loom-s:before {
  content: "\f527"
}

.sym-loom:before {
  content: "\f528"
}

.sym-lpt-s:before {
  content: "\f529"
}

.sym-lpt:before {
  content: "\f52a"
}

.sym-lrc-s:before {
  content: "\f52b"
}

.sym-lrc:before {
  content: "\f52c"
}

.sym-lrn-s:before {
  content: "\f52d"
}

.sym-lrn:before {
  content: "\f52e"
}

.sym-lsk-s:before {
  content: "\f52f"
}

.sym-lsk:before {
  content: "\f530"
}

.sym-ltc-s:before {
  content: "\f531"
}

.sym-ltc:before {
  content: "\f532"
}

.sym-lto-s:before {
  content: "\f533"
}

.sym-lto:before {
  content: "\f534"
}

.sym-lun-s:before {
  content: "\f535"
}

.sym-lun:before {
  content: "\f536"
}

.sym-luna-s:before {
  content: "\f537"
}

.sym-luna:before {
  content: "\f538"
}

.sym-lxt-s:before {
  content: "\f539"
}

.sym-lxt:before {
  content: "\f53a"
}

.sym-lym-s:before {
  content: "\f53b"
}

.sym-lym:before {
  content: "\f53c"
}

.sym-m2k-s:before {
  content: "\f53d"
}

.sym-m2k:before {
  content: "\f53e"
}

.sym-ma-s:before {
  content: "\f53f"
}

.sym-ma:before {
  content: "\f540"
}

.sym-maid-s:before {
  content: "\f541"
}

.sym-maid:before {
  content: "\f542"
}

.sym-man-s:before {
  content: "\f543"
}

.sym-man:before {
  content: "\f544"
}

.sym-mana-s:before {
  content: "\f545"
}

.sym-mana:before {
  content: "\f546"
}

.sym-mass-s:before {
  content: "\f547"
}

.sym-mass:before {
  content: "\f548"
}

.sym-matic-s:before {
  content: "\f549"
}

.sym-matic:before {
  content: "\f54a"
}

.sym-mbl-s:before {
  content: "\f54b"
}

.sym-mbl:before {
  content: "\f54c"
}

.sym-mbt-s:before {
  content: "\f54d"
}

.sym-mbt:before {
  content: "\f54e"
}

.sym-mco-s:before {
  content: "\f54f"
}

.sym-mco:before {
  content: "\f550"
}

.sym-mda-s:before {
  content: "\f551"
}

.sym-mda:before {
  content: "\f552"
}

.sym-mds-s:before {
  content: "\f553"
}

.sym-mds:before {
  content: "\f554"
}

.sym-mdt-s:before {
  content: "\f555"
}

.sym-mdt:before {
  content: "\f556"
}

.sym-mdx-s:before {
  content: "\f557"
}

.sym-mdx:before {
  content: "\f558"
}

.sym-med-s:before {
  content: "\f559"
}

.sym-med:before {
  content: "\f55a"
}

.sym-mer-s:before {
  content: "\f55b"
}

.sym-mer:before {
  content: "\f55c"
}

.sym-mes-s:before {
  content: "\f55d"
}

.sym-mes:before {
  content: "\f55e"
}

.sym-met-s:before {
  content: "\f55f"
}

.sym-met:before {
  content: "\f560"
}

.sym-meta-s:before {
  content: "\f561"
}

.sym-meta:before {
  content: "\f562"
}

.sym-mft-s:before {
  content: "\f563"
}

.sym-mft:before {
  content: "\f564"
}

.sym-mgc-s:before {
  content: "\f565"
}

.sym-mgc:before {
  content: "\f566"
}

.sym-mgo-s:before {
  content: "\f567"
}

.sym-mgo:before {
  content: "\f568"
}

.sym-mhc-s:before {
  content: "\f569"
}

.sym-mhc:before {
  content: "\f56a"
}

.sym-mina-s:before {
  content: "\f56b"
}

.sym-mina:before {
  content: "\f56c"
}

.sym-mir-s:before {
  content: "\f56d"
}

.sym-mir:before {
  content: "\f56e"
}

.sym-mith-s:before {
  content: "\f56f"
}

.sym-mith:before {
  content: "\f570"
}

.sym-mitx-s:before {
  content: "\f571"
}

.sym-mitx:before {
  content: "\f572"
}

.sym-mjp-s:before {
  content: "\f573"
}

.sym-mjp:before {
  content: "\f574"
}

.sym-mkr-s:before {
  content: "\f575"
}

.sym-mkr:before {
  content: "\f576"
}

.sym-mln-s:before {
  content: "\f577"
}

.sym-mln:before {
  content: "\f578"
}

.sym-mnx-s:before {
  content: "\f579"
}

.sym-mnx:before {
  content: "\f57a"
}

.sym-moac-s:before {
  content: "\f57b"
}

.sym-moac:before {
  content: "\f57c"
}

.sym-mob-s:before {
  content: "\f57d"
}

.sym-mob:before {
  content: "\f57e"
}

.sym-mobi-s:before {
  content: "\f57f"
}

.sym-mobi:before {
  content: "\f580"
}

.sym-moc-s:before {
  content: "\f581"
}

.sym-moc:before {
  content: "\f582"
}

.sym-mod-s:before {
  content: "\f583"
}

.sym-mod:before {
  content: "\f584"
}

.sym-mona-s:before {
  content: "\f585"
}

.sym-mona:before {
  content: "\f586"
}

.sym-moon-s:before {
  content: "\f587"
}

.sym-moon:before {
  content: "\f588"
}

.sym-morph-s:before {
  content: "\f589"
}

.sym-morph:before {
  content: "\f58a"
}

.sym-movr-s:before {
  content: "\f58b"
}

.sym-movr:before {
  content: "\f58c"
}

.sym-mrk-s:before {
  content: "\f58d"
}

.sym-mrk:before {
  content: "\f58e"
}

.sym-msp-s:before {
  content: "\f58f"
}

.sym-msp:before {
  content: "\f590"
}

.sym-mta-s:before {
  content: "\f591"
}

.sym-mta:before {
  content: "\f592"
}

.sym-mtc-s:before {
  content: "\f593"
}

.sym-mtc:before {
  content: "\f594"
}

.sym-mth-s:before {
  content: "\f595"
}

.sym-mth:before {
  content: "\f596"
}

.sym-mtl-s:before {
  content: "\f597"
}

.sym-mtl:before {
  content: "\f598"
}

.sym-mtn-s:before {
  content: "\f599"
}

.sym-mtn:before {
  content: "\f59a"
}

.sym-mtx-s:before {
  content: "\f59b"
}

.sym-mtx:before {
  content: "\f59c"
}

.sym-mue-s:before {
  content: "\f59d"
}

.sym-mue:before {
  content: "\f59e"
}

.sym-mx-s:before {
  content: "\f59f"
}

.sym-mx:before {
  content: "\f5a0"
}

.sym-mxc-s:before {
  content: "\f5a1"
}

.sym-mxc:before {
  content: "\f5a2"
}

.sym-mxm-s:before {
  content: "\f5a3"
}

.sym-mxm:before {
  content: "\f5a4"
}

.sym-mxn-s:before {
  content: "\f5a5"
}

.sym-mxn:before {
  content: "\f5a6"
}

.sym-myr-s:before {
  content: "\f5a7"
}

.sym-myr:before {
  content: "\f5a8"
}

.sym-n9l-s:before {
  content: "\f5a9"
}

.sym-n9l:before {
  content: "\f5aa"
}

.sym-nanj-s:before {
  content: "\f5ab"
}

.sym-nanj:before {
  content: "\f5ac"
}

.sym-nano-s:before {
  content: "\f5ad"
}

.sym-nano:before {
  content: "\f5ae"
}

.sym-nas-s:before {
  content: "\f5af"
}

.sym-nas:before {
  content: "\f5b0"
}

.sym-naut-s:before {
  content: "\f5b1"
}

.sym-naut:before {
  content: "\f5b2"
}

.sym-nav-s:before {
  content: "\f5b3"
}

.sym-nav:before {
  content: "\f5b4"
}

.sym-ncash-s:before {
  content: "\f5b5"
}

.sym-ncash:before {
  content: "\f5b6"
}

.sym-nct-s:before {
  content: "\f5b7"
}

.sym-nct:before {
  content: "\f5b8"
}

.sym-near-s:before {
  content: "\f5b9"
}

.sym-near:before {
  content: "\f5ba"
}

.sym-nebl-s:before {
  content: "\f5bb"
}

.sym-nebl:before {
  content: "\f5bc"
}

.sym-nec-s:before {
  content: "\f5bd"
}

.sym-nec:before {
  content: "\f5be"
}

.sym-neo-s:before {
  content: "\f5bf"
}

.sym-neo:before {
  content: "\f5c0"
}

.sym-neos-s:before {
  content: "\f5c1"
}

.sym-neos:before {
  content: "\f5c2"
}

.sym-nest-s:before {
  content: "\f5c3"
}

.sym-nest:before {
  content: "\f5c4"
}

.sym-neu-s:before {
  content: "\f5c5"
}

.sym-neu:before {
  content: "\f5c6"
}

.sym-new-s:before {
  content: "\f5c7"
}

.sym-new:before {
  content: "\f5c8"
}

.sym-nexo-s:before {
  content: "\f5c9"
}

.sym-nexo:before {
  content: "\f5ca"
}

.sym-nft-s:before {
  content: "\f5cb"
}

.sym-nft:before {
  content: "\f5cc"
}

.sym-ng-s:before {
  content: "\f5cd"
}

.sym-ng:before {
  content: "\f5ce"
}

.sym-ngc-s:before {
  content: "\f5cf"
}

.sym-ngc:before {
  content: "\f5d0"
}

.sym-ngn-s:before {
  content: "\f5d1"
}

.sym-ngn:before {
  content: "\f5d2"
}

.sym-nim-s:before {
  content: "\f5d3"
}

.sym-nim:before {
  content: "\f5d4"
}

.sym-niy-s:before {
  content: "\f5d5"
}

.sym-niy:before {
  content: "\f5d6"
}

.sym-nkd-s:before {
  content: "\f5d7"
}

.sym-nkd:before {
  content: "\f5d8"
}

.sym-nkn-s:before {
  content: "\f5d9"
}

.sym-nkn:before {
  content: "\f5da"
}

.sym-nlc2-s:before {
  content: "\f5db"
}

.sym-nlc2:before {
  content: "\f5dc"
}

.sym-nlg-s:before {
  content: "\f5dd"
}

.sym-nlg:before {
  content: "\f5de"
}

.sym-nmc-s:before {
  content: "\f5df"
}

.sym-nmc:before {
  content: "\f5e0"
}

.sym-nmr-s:before {
  content: "\f5e1"
}

.sym-nmr:before {
  content: "\f5e2"
}

.sym-nn-s:before {
  content: "\f5e3"
}

.sym-nn:before {
  content: "\f5e4"
}

.sym-noah-s:before {
  content: "\f5e5"
}

.sym-noah:before {
  content: "\f5e6"
}

.sym-note-s:before {
  content: "\f5e7"
}

.sym-note:before {
  content: "\f5e8"
}

.sym-npg-s:before {
  content: "\f5e9"
}

.sym-npg:before {
  content: "\f5ea"
}

.sym-nplc-s:before {
  content: "\f5eb"
}

.sym-nplc:before {
  content: "\f5ec"
}

.sym-npxs-s:before {
  content: "\f5ed"
}

.sym-npxs:before {
  content: "\f5ee"
}

.sym-nq-s:before {
  content: "\f5ef"
}

.sym-nq:before {
  content: "\f5f0"
}

.sym-nrg-s:before {
  content: "\f5f1"
}

.sym-nrg:before {
  content: "\f5f2"
}

.sym-ntk-s:before {
  content: "\f5f3"
}

.sym-ntk:before {
  content: "\f5f4"
}

.sym-nu-s:before {
  content: "\f5f5"
}

.sym-nu:before {
  content: "\f5f6"
}

.sym-nuls-s:before {
  content: "\f5f7"
}

.sym-nuls:before {
  content: "\f5f8"
}

.sym-nvc-s:before {
  content: "\f5f9"
}

.sym-nvc:before {
  content: "\f5fa"
}

.sym-nxc-s:before {
  content: "\f5fb"
}

.sym-nxc:before {
  content: "\f5fc"
}

.sym-nxs-s:before {
  content: "\f5fd"
}

.sym-nxs:before {
  content: "\f5fe"
}

.sym-nxt-s:before {
  content: "\f5ff"
}

.sym-nxt:before {
  content: "\f600"
}

.sym-o-s:before {
  content: "\f601"
}

.sym-o:before {
  content: "\f602"
}

.sym-oax-s:before {
  content: "\f603"
}

.sym-oax:before {
  content: "\f604"
}

.sym-ocean-s:before {
  content: "\f605"
}

.sym-ocean:before {
  content: "\f606"
}

.sym-ocn-s:before {
  content: "\f607"
}

.sym-ocn:before {
  content: "\f608"
}

.sym-ode-s:before {
  content: "\f609"
}

.sym-ode:before {
  content: "\f60a"
}

.sym-ogn-s:before {
  content: "\f60b"
}

.sym-ogn:before {
  content: "\f60c"
}

.sym-ogo-s:before {
  content: "\f60d"
}

.sym-ogo:before {
  content: "\f60e"
}

.sym-ok-s:before {
  content: "\f60f"
}

.sym-ok:before {
  content: "\f610"
}

.sym-okb-s:before {
  content: "\f611"
}

.sym-okb:before {
  content: "\f612"
}

.sym-om-s:before {
  content: "\f613"
}

.sym-om:before {
  content: "\f614"
}

.sym-omg-s:before {
  content: "\f615"
}

.sym-omg:before {
  content: "\f616"
}

.sym-omni-s:before {
  content: "\f617"
}

.sym-omni:before {
  content: "\f618"
}

.sym-one-s:before {
  content: "\f619"
}

.sym-one:before {
  content: "\f61a"
}

.sym-ong-s:before {
  content: "\f61b"
}

.sym-ong:before {
  content: "\f61c"
}

.sym-onot-s:before {
  content: "\f61d"
}

.sym-onot:before {
  content: "\f61e"
}

.sym-ont-s:before {
  content: "\f61f"
}

.sym-ont:before {
  content: "\f620"
}

.sym-orbs-s:before {
  content: "\f621"
}

.sym-orbs:before {
  content: "\f622"
}

.sym-orme-s:before {
  content: "\f623"
}

.sym-orme:before {
  content: "\f624"
}

.sym-ors-s:before {
  content: "\f625"
}

.sym-ors:before {
  content: "\f626"
}

.sym-ost-s:before {
  content: "\f627"
}

.sym-ost:before {
  content: "\f628"
}

.sym-otn-s:before {
  content: "\f629"
}

.sym-otn:before {
  content: "\f62a"
}

.sym-oxt-s:before {
  content: "\f62b"
}

.sym-oxt:before {
  content: "\f62c"
}

.sym-oxy-s:before {
  content: "\f62d"
}

.sym-oxy:before {
  content: "\f62e"
}

.sym-pai-s:before {
  content: "\f62f"
}

.sym-pai:before {
  content: "\f630"
}

.sym-pal-s:before {
  content: "\f631"
}

.sym-pal:before {
  content: "\f632"
}

.sym-para-s:before {
  content: "\f633"
}

.sym-para:before {
  content: "\f634"
}

.sym-part-s:before {
  content: "\f635"
}

.sym-part:before {
  content: "\f636"
}

.sym-pasc-s:before {
  content: "\f637"
}

.sym-pasc:before {
  content: "\f638"
}

.sym-pat-s:before {
  content: "\f639"
}

.sym-pat:before {
  content: "\f63a"
}

.sym-pax-s:before {
  content: "\f63b"
}

.sym-pax:before {
  content: "\f63c"
}

.sym-paxg-s:before {
  content: "\f63d"
}

.sym-paxg:before {
  content: "\f63e"
}

.sym-pay-s:before {
  content: "\f63f"
}

.sym-pay:before {
  content: "\f640"
}

.sym-pbt-s:before {
  content: "\f641"
}

.sym-pbt:before {
  content: "\f642"
}

.sym-pcl-s:before {
  content: "\f643"
}

.sym-pcl:before {
  content: "\f644"
}

.sym-pcx-s:before {
  content: "\f645"
}

.sym-pcx:before {
  content: "\f646"
}

.sym-perl-s:before {
  content: "\f647"
}

.sym-perl:before {
  content: "\f648"
}

.sym-perp-s:before {
  content: "\f649"
}

.sym-perp:before {
  content: "\f64a"
}

.sym-pha-s:before {
  content: "\f64b"
}

.sym-pha:before {
  content: "\f64c"
}

.sym-phb-s:before {
  content: "\f64d"
}

.sym-phb:before {
  content: "\f64e"
}

.sym-php-s:before {
  content: "\f64f"
}

.sym-php:before {
  content: "\f650"
}

.sym-phx-s:before {
  content: "\f651"
}

.sym-phx:before {
  content: "\f652"
}

.sym-pi-s:before {
  content: "\f653"
}

.sym-pi:before {
  content: "\f654"
}

.sym-pica-s:before {
  content: "\f655"
}

.sym-pica:before {
  content: "\f656"
}

.sym-pink-s:before {
  content: "\f657"
}

.sym-pink:before {
  content: "\f658"
}

.sym-pivx-s:before {
  content: "\f659"
}

.sym-pivx:before {
  content: "\f65a"
}

.sym-pkt-s:before {
  content: "\f65b"
}

.sym-pkt:before {
  content: "\f65c"
}

.sym-pl-s:before {
  content: "\f65d"
}

.sym-pl:before {
  content: "\f65e"
}

.sym-pla-s:before {
  content: "\f65f"
}

.sym-pla:before {
  content: "\f660"
}

.sym-plbt-s:before {
  content: "\f661"
}

.sym-plbt:before {
  content: "\f662"
}

.sym-plm-s:before {
  content: "\f663"
}

.sym-plm:before {
  content: "\f664"
}

.sym-pln-s:before {
  content: "\f665"
}

.sym-pln:before {
  content: "\f666"
}

.sym-plr-s:before {
  content: "\f667"
}

.sym-plr:before {
  content: "\f668"
}

.sym-ply-s:before {
  content: "\f669"
}

.sym-ply:before {
  content: "\f66a"
}

.sym-pma-s:before {
  content: "\f66b"
}

.sym-pma:before {
  content: "\f66c"
}

.sym-pnt-s:before {
  content: "\f66d"
}

.sym-pnt:before {
  content: "\f66e"
}

.sym-poa-s:before {
  content: "\f66f"
}

.sym-poa:before {
  content: "\f670"
}

.sym-poe-s:before {
  content: "\f671"
}

.sym-poe:before {
  content: "\f672"
}

.sym-pols-s:before {
  content: "\f673"
}

.sym-pols:before {
  content: "\f674"
}

.sym-poly-s:before {
  content: "\f675"
}

.sym-poly:before {
  content: "\f676"
}

.sym-pond-s:before {
  content: "\f677"
}

.sym-pond:before {
  content: "\f678"
}

.sym-pot-s:before {
  content: "\f679"
}

.sym-pot:before {
  content: "\f67a"
}

.sym-powr-s:before {
  content: "\f67b"
}

.sym-powr:before {
  content: "\f67c"
}

.sym-ppc-s:before {
  content: "\f67d"
}

.sym-ppc:before {
  content: "\f67e"
}

.sym-ppt-s:before {
  content: "\f67f"
}

.sym-ppt:before {
  content: "\f680"
}

.sym-pra-s:before {
  content: "\f681"
}

.sym-pra:before {
  content: "\f682"
}

.sym-pre-s:before {
  content: "\f683"
}

.sym-pre:before {
  content: "\f684"
}

.sym-prg-s:before {
  content: "\f685"
}

.sym-prg:before {
  content: "\f686"
}

.sym-pro-s:before {
  content: "\f687"
}

.sym-pro:before {
  content: "\f688"
}

.sym-pst-s:before {
  content: "\f689"
}

.sym-pst:before {
  content: "\f68a"
}

.sym-pton-s:before {
  content: "\f68b"
}

.sym-pton:before {
  content: "\f68c"
}

.sym-pvt-s:before {
  content: "\f68d"
}

.sym-pvt:before {
  content: "\f68e"
}

.sym-pxg-s:before {
  content: "\f68f"
}

.sym-pxg:before {
  content: "\f690"
}

.sym-qash-s:before {
  content: "\f691"
}

.sym-qash:before {
  content: "\f692"
}

.sym-qau-s:before {
  content: "\f693"
}

.sym-qau:before {
  content: "\f694"
}

.sym-qc-s:before {
  content: "\f695"
}

.sym-qc:before {
  content: "\f696"
}

.sym-qi-s:before {
  content: "\f697"
}

.sym-qi:before {
  content: "\f698"
}

.sym-qkc-s:before {
  content: "\f699"
}

.sym-qkc:before {
  content: "\f69a"
}

.sym-qlc-s:before {
  content: "\f69b"
}

.sym-qlc:before {
  content: "\f69c"
}

.sym-qnt-s:before {
  content: "\f69d"
}

.sym-qnt:before {
  content: "\f69e"
}

.sym-qntu-s:before {
  content: "\f69f"
}

.sym-qntu:before {
  content: "\f6a0"
}

.sym-qo-s:before {
  content: "\f6a1"
}

.sym-qo:before {
  content: "\f6a2"
}

.sym-qrl-s:before {
  content: "\f6a3"
}

.sym-qrl:before {
  content: "\f6a4"
}

.sym-qsp-s:before {
  content: "\f6a5"
}

.sym-qsp:before {
  content: "\f6a6"
}

.sym-qtum-s:before {
  content: "\f6a7"
}

.sym-qtum:before {
  content: "\f6a8"
}

.sym-qun-s:before {
  content: "\f6a9"
}

.sym-qun:before {
  content: "\f6aa"
}

.sym-r-s:before {
  content: "\f6ab"
}

.sym-r:before {
  content: "\f6ac"
}

.sym-rad-s:before {
  content: "\f6ad"
}

.sym-rad:before {
  content: "\f6ae"
}

.sym-rads-s:before {
  content: "\f6af"
}

.sym-rads:before {
  content: "\f6b0"
}

.sym-rari-s:before {
  content: "\f6b1"
}

.sym-rari:before {
  content: "\f6b2"
}

.sym-rating-s:before {
  content: "\f6b3"
}

.sym-rating:before {
  content: "\f6b4"
}

.sym-ray-s:before {
  content: "\f6b5"
}

.sym-ray:before {
  content: "\f6b6"
}

.sym-rb-s:before {
  content: "\f6b7"
}

.sym-rb:before {
  content: "\f6b8"
}

.sym-rblx-s:before {
  content: "\f6b9"
}

.sym-rblx:before {
  content: "\f6ba"
}

.sym-rbtc-s:before {
  content: "\f6bb"
}

.sym-rbtc:before {
  content: "\f6bc"
}

.sym-rby-s:before {
  content: "\f6bd"
}

.sym-rby:before {
  content: "\f6be"
}

.sym-rcn-s:before {
  content: "\f6bf"
}

.sym-rcn:before {
  content: "\f6c0"
}

.sym-rdd-s:before {
  content: "\f6c1"
}

.sym-rdd:before {
  content: "\f6c2"
}

.sym-rdn-s:before {
  content: "\f6c3"
}

.sym-rdn:before {
  content: "\f6c4"
}

.sym-reef-s:before {
  content: "\f6c5"
}

.sym-reef:before {
  content: "\f6c6"
}

.sym-rem-s:before {
  content: "\f6c7"
}

.sym-rem:before {
  content: "\f6c8"
}

.sym-ren-s:before {
  content: "\f6c9"
}

.sym-ren:before {
  content: "\f6ca"
}

.sym-rep-s:before {
  content: "\f6cb"
}

.sym-rep:before {
  content: "\f6cc"
}

.sym-repv2-s:before {
  content: "\f6cd"
}

.sym-repv2:before {
  content: "\f6ce"
}

.sym-req-s:before {
  content: "\f6cf"
}

.sym-req:before {
  content: "\f6d0"
}

.sym-rev-s:before {
  content: "\f6d1"
}

.sym-rev:before {
  content: "\f6d2"
}

.sym-rfox-s:before {
  content: "\f6d3"
}

.sym-rfox:before {
  content: "\f6d4"
}

.sym-rfr-s:before {
  content: "\f6d5"
}

.sym-rfr:before {
  content: "\f6d6"
}

.sym-ric-s:before {
  content: "\f6d7"
}

.sym-ric:before {
  content: "\f6d8"
}

.sym-rif-s:before {
  content: "\f6d9"
}

.sym-rif:before {
  content: "\f6da"
}

.sym-ring-s:before {
  content: "\f6db"
}

.sym-ring:before {
  content: "\f6dc"
}

.sym-rlc-s:before {
  content: "\f6dd"
}

.sym-rlc:before {
  content: "\f6de"
}

.sym-rmrk-s:before {
  content: "\f6df"
}

.sym-rmrk:before {
  content: "\f6e0"
}

.sym-rntb-s:before {
  content: "\f6e1"
}

.sym-rntb:before {
  content: "\f6e2"
}

.sym-rose-s:before {
  content: "\f6e3"
}

.sym-rose:before {
  content: "\f6e4"
}

.sym-rox-s:before {
  content: "\f6e5"
}

.sym-rox:before {
  content: "\f6e6"
}

.sym-rp-s:before {
  content: "\f6e7"
}

.sym-rp:before {
  content: "\f6e8"
}

.sym-rpx-s:before {
  content: "\f6e9"
}

.sym-rpx:before {
  content: "\f6ea"
}

.sym-rsr-s:before {
  content: "\f6eb"
}

.sym-rsr:before {
  content: "\f6ec"
}

.sym-rsv-s:before {
  content: "\f6ed"
}

.sym-rsv:before {
  content: "\f6ee"
}

.sym-rty-s:before {
  content: "\f6ef"
}

.sym-rty:before {
  content: "\f6f0"
}

.sym-rub-s:before {
  content: "\f6f1"
}

.sym-rub:before {
  content: "\f6f2"
}

.sym-ruff-s:before {
  content: "\f6f3"
}

.sym-ruff:before {
  content: "\f6f4"
}

.sym-rune-s:before {
  content: "\f6f5"
}

.sym-rune:before {
  content: "\f6f6"
}

.sym-rvn-s:before {
  content: "\f6f7"
}

.sym-rvn:before {
  content: "\f6f8"
}

.sym-rvr-s:before {
  content: "\f6f9"
}

.sym-rvr:before {
  content: "\f6fa"
}

.sym-rvt-s:before {
  content: "\f6fb"
}

.sym-rvt:before {
  content: "\f6fc"
}

.sym-sai-s:before {
  content: "\f6fd"
}

.sym-sai:before {
  content: "\f6fe"
}

.sym-salt-s:before {
  content: "\f6ff"
}

.sym-salt:before {
  content: "\f700"
}

.sym-san-s:before {
  content: "\f701"
}

.sym-san:before {
  content: "\f702"
}

.sym-sand-s:before {
  content: "\f703"
}

.sym-sand:before {
  content: "\f704"
}

.sym-sats-s:before {
  content: "\f705"
}

.sym-sats:before {
  content: "\f706"
}

.sym-sbd-s:before {
  content: "\f707"
}

.sym-sbd:before {
  content: "\f708"
}

.sym-sc-s:before {
  content: "\f709"
}

.sym-sc:before {
  content: "\f70a"
}

.sym-scc-s:before {
  content: "\f70b"
}

.sym-scc:before {
  content: "\f70c"
}

.sym-scrt-s:before {
  content: "\f70d"
}

.sym-scrt:before {
  content: "\f70e"
}

.sym-sdc-s:before {
  content: "\f70f"
}

.sym-sdc:before {
  content: "\f710"
}

.sym-sdn-s:before {
  content: "\f711"
}

.sym-sdn:before {
  content: "\f712"
}

.sym-seele-s:before {
  content: "\f713"
}

.sym-seele:before {
  content: "\f714"
}

.sym-sek-s:before {
  content: "\f715"
}

.sym-sek:before {
  content: "\f716"
}

.sym-sen-s:before {
  content: "\f717"
}

.sym-sen:before {
  content: "\f718"
}

.sym-sent-s:before {
  content: "\f719"
}

.sym-sent:before {
  content: "\f71a"
}

.sym-sero-s:before {
  content: "\f71b"
}

.sym-sero:before {
  content: "\f71c"
}

.sym-sexc-s:before {
  content: "\f71d"
}

.sym-sexc:before {
  content: "\f71e"
}

.sym-sfp-s:before {
  content: "\f71f"
}

.sym-sfp:before {
  content: "\f720"
}

.sym-sgb-s:before {
  content: "\f721"
}

.sym-sgb:before {
  content: "\f722"
}

.sym-sgc-s:before {
  content: "\f723"
}

.sym-sgc:before {
  content: "\f724"
}

.sym-sgd-s:before {
  content: "\f725"
}

.sym-sgd:before {
  content: "\f726"
}

.sym-sgn-s:before {
  content: "\f727"
}

.sym-sgn:before {
  content: "\f728"
}

.sym-sgu-s:before {
  content: "\f729"
}

.sym-sgu:before {
  content: "\f72a"
}

.sym-shib-s:before {
  content: "\f72b"
}

.sym-shib:before {
  content: "\f72c"
}

.sym-shift-s:before {
  content: "\f72d"
}

.sym-shift:before {
  content: "\f72e"
}

.sym-ship-s:before {
  content: "\f72f"
}

.sym-ship:before {
  content: "\f730"
}

.sym-si-s:before {
  content: "\f731"
}

.sym-si:before {
  content: "\f732"
}

.sym-sib-s:before {
  content: "\f733"
}

.sym-sib:before {
  content: "\f734"
}

.sym-sil-s:before {
  content: "\f735"
}

.sym-sil:before {
  content: "\f736"
}

.sym-six-s:before {
  content: "\f737"
}

.sym-six:before {
  content: "\f738"
}

.sym-sjcx-s:before {
  content: "\f739"
}

.sym-sjcx:before {
  content: "\f73a"
}

.sym-skl-s:before {
  content: "\f73b"
}

.sym-skl:before {
  content: "\f73c"
}

.sym-skm-s:before {
  content: "\f73d"
}

.sym-skm:before {
  content: "\f73e"
}

.sym-sku-s:before {
  content: "\f73f"
}

.sym-sku:before {
  content: "\f740"
}

.sym-sky-s:before {
  content: "\f741"
}

.sym-sky:before {
  content: "\f742"
}

.sym-slr-s:before {
  content: "\f743"
}

.sym-slr:before {
  content: "\f744"
}

.sym-sls-s:before {
  content: "\f745"
}

.sym-sls:before {
  content: "\f746"
}

.sym-slt-s:before {
  content: "\f747"
}

.sym-slt:before {
  content: "\f748"
}

.sym-slv-s:before {
  content: "\f749"
}

.sym-slv:before {
  content: "\f74a"
}

.sym-smart-s:before {
  content: "\f74b"
}

.sym-smart:before {
  content: "\f74c"
}

.sym-smn-s:before {
  content: "\f74d"
}

.sym-smn:before {
  content: "\f74e"
}

.sym-smt-s:before {
  content: "\f74f"
}

.sym-smt:before {
  content: "\f750"
}

.sym-snc-s:before {
  content: "\f751"
}

.sym-snc:before {
  content: "\f752"
}

.sym-snet-s:before {
  content: "\f753"
}

.sym-snet:before {
  content: "\f754"
}

.sym-sngls-s:before {
  content: "\f755"
}

.sym-sngls:before {
  content: "\f756"
}

.sym-snm-s:before {
  content: "\f757"
}

.sym-snm:before {
  content: "\f758"
}

.sym-snt-s:before {
  content: "\f759"
}

.sym-snt:before {
  content: "\f75a"
}

.sym-snx-s:before {
  content: "\f75b"
}

.sym-snx:before {
  content: "\f75c"
}

.sym-soc-s:before {
  content: "\f75d"
}

.sym-soc:before {
  content: "\f75e"
}

.sym-sol-s:before {
  content: "\f75f"
}

.sym-sol:before {
  content: "\f760"
}

.sym-solo-s:before {
  content: "\f761"
}

.sym-solo:before {
  content: "\f762"
}

.sym-solve-s:before {
  content: "\f763"
}

.sym-solve:before {
  content: "\f764"
}

.sym-soul-s:before {
  content: "\f765"
}

.sym-soul:before {
  content: "\f766"
}

.sym-sp-s:before {
  content: "\f767"
}

.sym-sp:before {
  content: "\f768"
}

.sym-sparta-s:before {
  content: "\f769"
}

.sym-sparta:before {
  content: "\f76a"
}

.sym-spc-s:before {
  content: "\f76b"
}

.sym-spc:before {
  content: "\f76c"
}

.sym-spd-s:before {
  content: "\f76d"
}

.sym-spd:before {
  content: "\f76e"
}

.sym-sphr-s:before {
  content: "\f76f"
}

.sym-sphr:before {
  content: "\f770"
}

.sym-sphtx-s:before {
  content: "\f771"
}

.sym-sphtx:before {
  content: "\f772"
}

.sym-spnd-s:before {
  content: "\f773"
}

.sym-spnd:before {
  content: "\f774"
}

.sym-spnk-s:before {
  content: "\f775"
}

.sym-spnk:before {
  content: "\f776"
}

.sym-srm-s:before {
  content: "\f777"
}

.sym-srm:before {
  content: "\f778"
}

.sym-srn-s:before {
  content: "\f779"
}

.sym-srn:before {
  content: "\f77a"
}

.sym-ssp-s:before {
  content: "\f77b"
}

.sym-ssp:before {
  content: "\f77c"
}

.sym-stacs-s:before {
  content: "\f77d"
}

.sym-stacs:before {
  content: "\f77e"
}

.sym-storm-s:before {
  content: "\f77f"
}

.sym-storm:before {
  content: "\f780"
}

.sym-stpt-s:before {
  content: "\f781"
}

.sym-stpt:before {
  content: "\f782"
}

.sym-stq-s:before {
  content: "\f783"
}

.sym-stq:before {
  content: "\f784"
}

.sym-str-s:before {
  content: "\f785"
}

.sym-str:before {
  content: "\f786"
}

.sym-strat-s:before {
  content: "\f787"
}

.sym-strat:before {
  content: "\f788"
}

.sym-strax-s:before {
  content: "\f789"
}

.sym-strax:before {
  content: "\f78a"
}

.sym-stx-s:before {
  content: "\f78b"
}

.sym-stx:before {
  content: "\f78c"
}

.sym-sub-s:before {
  content: "\f78d"
}

.sym-sub:before {
  content: "\f78e"
}

.sym-susd-s:before {
  content: "\f78f"
}

.sym-susd:before {
  content: "\f790"
}

.sym-sushi-s:before {
  content: "\f791"
}

.sym-sushi:before {
  content: "\f792"
}

.sym-swftc-s:before {
  content: "\f793"
}

.sym-swftc:before {
  content: "\f794"
}

.sym-swm-s:before {
  content: "\f795"
}

.sym-swm:before {
  content: "\f796"
}

.sym-swrv-s:before {
  content: "\f797"
}

.sym-swrv:before {
  content: "\f798"
}

.sym-swt-s:before {
  content: "\f799"
}

.sym-swt:before {
  content: "\f79a"
}

.sym-swth-s:before {
  content: "\f79b"
}

.sym-swth:before {
  content: "\f79c"
}

.sym-sxp-s:before {
  content: "\f79d"
}

.sym-sxp:before {
  content: "\f79e"
}

.sym-sys-s:before {
  content: "\f79f"
}

.sym-sys:before {
  content: "\f7a0"
}

.sym-taas-s:before {
  content: "\f7a1"
}

.sym-taas:before {
  content: "\f7a2"
}

.sym-tau-s:before {
  content: "\f7a3"
}

.sym-tau:before {
  content: "\f7a4"
}

.sym-tbtc-s:before {
  content: "\f7a5"
}

.sym-tbtc:before {
  content: "\f7a6"
}

.sym-tct-s:before {
  content: "\f7a7"
}

.sym-tct:before {
  content: "\f7a8"
}

.sym-tel-s:before {
  content: "\f7a9"
}

.sym-temco-s:before {
  content: "\f7aa"
}

.sym-temco:before {
  content: "\f7ab"
}

.sym-tfuel-s:before {
  content: "\f7ac"
}

.sym-tfuel:before {
  content: "\f7ad"
}

.sym-thb-s:before {
  content: "\f7ae"
}

.sym-thb:before {
  content: "\f7af"
}

.sym-thc-s:before {
  content: "\f7b0"
}

.sym-thc:before {
  content: "\f7b1"
}

.sym-theta-s:before {
  content: "\f7b2"
}

.sym-theta:before {
  content: "\f7b3"
}

.sym-thx-s:before {
  content: "\f7b4"
}

.sym-thx:before {
  content: "\f7b5"
}

.sym-time-s:before {
  content: "\f7b6"
}

.sym-time:before {
  content: "\f7b7"
}

.sym-tio-s:before {
  content: "\f7b8"
}

.sym-tio:before {
  content: "\f7b9"
}

.sym-tix-s:before {
  content: "\f7ba"
}

.sym-tix:before {
  content: "\f7bb"
}

.sym-tkn-s:before {
  content: "\f7bc"
}

.sym-tkn:before {
  content: "\f7bd"
}

.sym-tky-s:before {
  content: "\f7be"
}

.sym-tky:before {
  content: "\f7bf"
}

.sym-tnb-s:before {
  content: "\f7c0"
}

.sym-tnb:before {
  content: "\f7c1"
}

.sym-tnc-s:before {
  content: "\f7c2"
}

.sym-tnc:before {
  content: "\f7c3"
}

.sym-tnt-s:before {
  content: "\f7c4"
}

.sym-tnt:before {
  content: "\f7c5"
}

.sym-tomo-s:before {
  content: "\f7c6"
}

.sym-tomo:before {
  content: "\f7c7"
}

.sym-top-s:before {
  content: "\f7c8"
}

.sym-top:before {
  content: "\f7c9"
}

.sym-torn-s:before {
  content: "\f7ca"
}

.sym-torn:before {
  content: "\f7cb"
}

.sym-tpay-s:before {
  content: "\f7cc"
}

.sym-tpay:before {
  content: "\f7cd"
}

.sym-trac-s:before {
  content: "\f7ce"
}

.sym-trac:before {
  content: "\f7cf"
}

.sym-trb-s:before {
  content: "\f7d0"
}

.sym-trb:before {
  content: "\f7d1"
}

.sym-trig-s:before {
  content: "\f7d2"
}

.sym-trig:before {
  content: "\f7d3"
}

.sym-trio-s:before {
  content: "\f7d4"
}

.sym-trio:before {
  content: "\f7d5"
}

.sym-troy-s:before {
  content: "\f7d6"
}

.sym-troy:before {
  content: "\f7d7"
}

.sym-trst-s:before {
  content: "\f7d8"
}

.sym-trst:before {
  content: "\f7d9"
}

.sym-tru-s:before {
  content: "\f7da"
}

.sym-tru:before {
  content: "\f7db"
}

.sym-true-s:before {
  content: "\f7dc"
}

.sym-true:before {
  content: "\f7dd"
}

.sym-trx-s:before {
  content: "\f7de"
}

.sym-trx:before {
  content: "\f7df"
}

.sym-try-s:before {
  content: "\f7e0"
}

.sym-try:before {
  content: "\f7e1"
}

.sym-tryb-s:before {
  content: "\f7e2"
}

.sym-tryb:before {
  content: "\f7e3"
}

.sym-tt-s:before {
  content: "\f7e4"
}

.sym-tt:before {
  content: "\f7e5"
}

.sym-ttc-s:before {
  content: "\f7e6"
}

.sym-ttc:before {
  content: "\f7e7"
}

.sym-ttt-s:before {
  content: "\f7e8"
}

.sym-ttt:before {
  content: "\f7e9"
}

.sym-ttu-s:before {
  content: "\f7ea"
}

.sym-ttu:before {
  content: "\f7eb"
}

.sym-tube-s:before {
  content: "\f7ec"
}

.sym-tube:before {
  content: "\f7ed"
}

.sym-tusd-s:before {
  content: "\f7ee"
}

.sym-tusd:before {
  content: "\f7ef"
}

.sym-twt-s:before {
  content: "\f7f0"
}

.sym-twt:before {
  content: "\f7f1"
}

.sym-uah-s:before {
  content: "\f7f2"
}

.sym-uah:before {
  content: "\f7f3"
}

.sym-ubq-s:before {
  content: "\f7f4"
}

.sym-ubq:before {
  content: "\f7f5"
}

.sym-ubt-s:before {
  content: "\f7f6"
}

.sym-ubt:before {
  content: "\f7f7"
}

.sym-uft-s:before {
  content: "\f7f8"
}

.sym-uft:before {
  content: "\f7f9"
}

.sym-ugas-s:before {
  content: "\f7fa"
}

.sym-ugas:before {
  content: "\f7fb"
}

.sym-uip-s:before {
  content: "\f7fc"
}

.sym-uip:before {
  content: "\f7fd"
}

.sym-ukg-s:before {
  content: "\f7fe"
}

.sym-ukg:before {
  content: "\f7ff"
}

.sym-uma-s:before {
  content: "\f800"
}

.sym-uma:before {
  content: "\f801"
}

.sym-unfi-s:before {
  content: "\f802"
}

.sym-unfi:before {
  content: "\f803"
}

.sym-uni-s:before {
  content: "\f804"
}

.sym-uni:before {
  content: "\f805"
}

.sym-up-s:before {
  content: "\f806"
}

.sym-up:before {
  content: "\f807"
}

.sym-upp-s:before {
  content: "\f808"
}

.sym-upp:before {
  content: "\f809"
}

.sym-usd-s:before {
  content: "\f80a"
}

.sym-usd:before {
  content: "\f80b"
}

.sym-usdc-s:before {
  content: "\f80c"
}

.sym-usdc:before {
  content: "\f80d"
}

.sym-usds-s:before {
  content: "\f80e"
}

.sym-usds:before {
  content: "\f80f"
}

.sym-usk-s:before {
  content: "\f810"
}

.sym-usk:before {
  content: "\f811"
}

.sym-ust-s:before {
  content: "\f812"
}

.sym-ust:before {
  content: "\f813"
}

.sym-utk-s:before {
  content: "\f814"
}

.sym-utk:before {
  content: "\f815"
}

.sym-utnp-s:before {
  content: "\f816"
}

.sym-utnp:before {
  content: "\f817"
}

.sym-utt-s:before {
  content: "\f818"
}

.sym-utt:before {
  content: "\f819"
}

.sym-uuu-s:before {
  content: "\f81a"
}

.sym-uuu:before {
  content: "\f81b"
}

.sym-ux-s:before {
  content: "\f81c"
}

.sym-ux:before {
  content: "\f81d"
}

.sym-vai-s:before {
  content: "\f81e"
}

.sym-vai:before {
  content: "\f81f"
}

.sym-vbk-s:before {
  content: "\f820"
}

.sym-vbk:before {
  content: "\f821"
}

.sym-vdx-s:before {
  content: "\f822"
}

.sym-vdx:before {
  content: "\f823"
}

.sym-vee-s:before {
  content: "\f824"
}

.sym-vee:before {
  content: "\f825"
}

.sym-ven-s:before {
  content: "\f826"
}

.sym-ven:before {
  content: "\f827"
}

.sym-veo-s:before {
  content: "\f828"
}

.sym-veo:before {
  content: "\f829"
}

.sym-veri-s:before {
  content: "\f82a"
}

.sym-veri:before {
  content: "\f82b"
}

.sym-vex-s:before {
  content: "\f82c"
}

.sym-vex:before {
  content: "\f82d"
}

.sym-vgx-s:before {
  content: "\f82e"
}

.sym-vgx:before {
  content: "\f82f"
}

.sym-via-s:before {
  content: "\f830"
}

.sym-via:before {
  content: "\f831"
}

.sym-vib-s:before {
  content: "\f832"
}

.sym-vib:before {
  content: "\f833"
}

.sym-vibe-s:before {
  content: "\f834"
}

.sym-vibe:before {
  content: "\f835"
}

.sym-vid-s:before {
  content: "\f836"
}

.sym-vid:before {
  content: "\f837"
}

.sym-vidt-s:before {
  content: "\f838"
}

.sym-vidt:before {
  content: "\f839"
}

.sym-vidy-s:before {
  content: "\f83a"
}

.sym-vidy:before {
  content: "\f83b"
}

.sym-vitae-s:before {
  content: "\f83c"
}

.sym-vitae:before {
  content: "\f83d"
}

.sym-vite-s:before {
  content: "\f83e"
}

.sym-vite:before {
  content: "\f83f"
}

.sym-vlx-s:before {
  content: "\f840"
}

.sym-vlx:before {
  content: "\f841"
}

.sym-vox-s:before {
  content: "\f842"
}

.sym-vox:before {
  content: "\f843"
}

.sym-vra-s:before {
  content: "\f844"
}

.sym-vra:before {
  content: "\f845"
}

.sym-vrc-s:before {
  content: "\f846"
}

.sym-vrc:before {
  content: "\f847"
}

.sym-vrm-s:before {
  content: "\f848"
}

.sym-vrm:before {
  content: "\f849"
}

.sym-vsys-s:before {
  content: "\f84a"
}

.sym-vsys:before {
  content: "\f84b"
}

.sym-vtc-s:before {
  content: "\f84c"
}

.sym-vtc:before {
  content: "\f84d"
}

.sym-vtho-s:before {
  content: "\f84e"
}

.sym-vtho:before {
  content: "\f84f"
}

.sym-wabi-s:before {
  content: "\f850"
}

.sym-wabi:before {
  content: "\f851"
}

.sym-wan-s:before {
  content: "\f852"
}

.sym-wan:before {
  content: "\f853"
}

.sym-waves-s:before {
  content: "\f854"
}

.sym-waves:before {
  content: "\f855"
}

.sym-wax-s:before {
  content: "\f856"
}

.sym-wax:before {
  content: "\f857"
}

.sym-wbtc-s:before {
  content: "\f858"
}

.sym-wbtc:before {
  content: "\f859"
}

.sym-wet-s:before {
  content: "\f85a"
}

.sym-wet:before {
  content: "\f85b"
}

.sym-wib-s:before {
  content: "\f85c"
}

.sym-wib:before {
  content: "\f85d"
}

.sym-wicc-s:before {
  content: "\f85e"
}

.sym-wicc:before {
  content: "\f85f"
}

.sym-win-s:before {
  content: "\f860"
}

.sym-win:before {
  content: "\f861"
}

.sym-wing-s:before {
  content: "\f862"
}

.sym-wing:before {
  content: "\f863"
}

.sym-wings-s:before {
  content: "\f864"
}

.sym-wings:before {
  content: "\f865"
}

.sym-wnxm-s:before {
  content: "\f866"
}

.sym-wnxm:before {
  content: "\f867"
}

.sym-woo-s:before {
  content: "\f868"
}

.sym-woo:before {
  content: "\f869"
}

.sym-wpr-s:before {
  content: "\f86a"
}

.sym-wpr:before {
  content: "\f86b"
}

.sym-wrx-s:before {
  content: "\f86c"
}

.sym-wrx:before {
  content: "\f86d"
}

.sym-wtc-s:before {
  content: "\f86e"
}

.sym-wtc:before {
  content: "\f86f"
}

.sym-wtt-s:before {
  content: "\f870"
}

.sym-wtt:before {
  content: "\f871"
}

.sym-wwb-s:before {
  content: "\f872"
}

.sym-wwb:before {
  content: "\f873"
}

.sym-wxt-s:before {
  content: "\f874"
}

.sym-wxt:before {
  content: "\f875"
}

.sym-xas-s:before {
  content: "\f876"
}

.sym-xas:before {
  content: "\f877"
}

.sym-xaur-s:before {
  content: "\f878"
}

.sym-xaur:before {
  content: "\f879"
}

.sym-xaut-s:before {
  content: "\f87a"
}

.sym-xaut:before {
  content: "\f87b"
}

.sym-xbc-s:before {
  content: "\f87c"
}

.sym-xbc:before {
  content: "\f87d"
}

.sym-xcon-s:before {
  content: "\f87e"
}

.sym-xcon:before {
  content: "\f87f"
}

.sym-xcp-s:before {
  content: "\f880"
}

.sym-xcp:before {
  content: "\f881"
}

.sym-xdn-s:before {
  content: "\f882"
}

.sym-xdn:before {
  content: "\f883"
}

.sym-xel-s:before {
  content: "\f884"
}

.sym-xel:before {
  content: "\f885"
}

.sym-xem-s:before {
  content: "\f886"
}

.sym-xem:before {
  content: "\f887"
}

.sym-xes-s:before {
  content: "\f888"
}

.sym-xes:before {
  content: "\f889"
}

.sym-xhv-s:before {
  content: "\f88a"
}

.sym-xhv:before {
  content: "\f88b"
}

.sym-xin-s:before {
  content: "\f88c"
}

.sym-xin:before {
  content: "\f88d"
}

.sym-xlm-s:before {
  content: "\f88e"
}

.sym-xlm:before {
  content: "\f88f"
}

.sym-xmc-s:before {
  content: "\f890"
}

.sym-xmc:before {
  content: "\f891"
}

.sym-xmr-s:before {
  content: "\f892"
}

.sym-xmr:before {
  content: "\f893"
}

.sym-xmx-s:before {
  content: "\f894"
}

.sym-xmx:before {
  content: "\f895"
}

.sym-xmy-s:before {
  content: "\f896"
}

.sym-xmy:before {
  content: "\f897"
}

.sym-xnk-s:before {
  content: "\f898"
}

.sym-xnk:before {
  content: "\f899"
}

.sym-xns-s:before {
  content: "\f89a"
}

.sym-xns:before {
  content: "\f89b"
}

.sym-xor-s:before {
  content: "\f89c"
}

.sym-xor:before {
  content: "\f89d"
}

.sym-xos-s:before {
  content: "\f89e"
}

.sym-xos:before {
  content: "\f89f"
}

.sym-xpm-s:before {
  content: "\f8a0"
}

.sym-xpm:before {
  content: "\f8a1"
}

.sym-xpr-s:before {
  content: "\f8a2"
}

.sym-xpr:before {
  content: "\f8a3"
}

.sym-xrc-s:before {
  content: "\f8a4"
}

.sym-xrc:before {
  content: "\f8a5"
}

.sym-xrp-s:before {
  content: "\f8a6"
}

.sym-xrp:before {
  content: "\f8a7"
}

.sym-xrpx-s:before {
  content: "\f8a8"
}

.sym-xrpx:before {
  content: "\f8a9"
}

.sym-xrt-s:before {
  content: "\f8aa"
}

.sym-xrt:before {
  content: "\f8ab"
}

.sym-xst-s:before {
  content: "\f8ac"
}

.sym-xst:before {
  content: "\f8ad"
}

.sym-xtp-s:before {
  content: "\f8ae"
}

.sym-xtp:before {
  content: "\f8af"
}

.sym-xtz-s:before {
  content: "\f8b0"
}

.sym-xtz:before {
  content: "\f8b1"
}

.sym-xtzdown-s:before {
  content: "\f8b2"
}

.sym-xtzdown:before {
  content: "\f8b3"
}

.sym-xvc-s:before {
  content: "\f8b4"
}

.sym-xvc:before {
  content: "\f8b5"
}

.sym-xvg-s:before {
  content: "\f8b6"
}

.sym-xvg:before {
  content: "\f8b7"
}

.sym-xvs-s:before {
  content: "\f8b8"
}

.sym-xvs:before {
  content: "\f8b9"
}

.sym-xwc-s:before {
  content: "\f8ba"
}

.sym-xwc:before {
  content: "\f8bb"
}

.sym-xyo-s:before {
  content: "\f8bc"
}

.sym-xyo:before {
  content: "\f8bd"
}

.sym-xzc-s:before {
  content: "\f8be"
}

.sym-xzc:before {
  content: "\f8bf"
}

.sym-yam-s:before {
  content: "\f8c0"
}

.sym-yam:before {
  content: "\f8c1"
}

.sym-yee-s:before {
  content: "\f8c2"
}

.sym-yee:before {
  content: "\f8c3"
}

.sym-yeed-s:before {
  content: "\f8c4"
}

.sym-yeed:before {
  content: "\f8c5"
}

.sym-yfi-s:before {
  content: "\f8c6"
}

.sym-yfi:before {
  content: "\f8c7"
}

.sym-yfii-s:before {
  content: "\f8c8"
}

.sym-yfii:before {
  content: "\f8c9"
}

.sym-yoyow-s:before {
  content: "\f8ca"
}

.sym-yoyow:before {
  content: "\f8cb"
}

.sym-zar-s:before {
  content: "\f8cc"
}

.sym-zar:before {
  content: "\f8cd"
}

.sym-zcl-s:before {
  content: "\f8ce"
}

.sym-zcl:before {
  content: "\f8cf"
}

.sym-zcn-s:before {
  content: "\f8d0"
}

.sym-zcn:before {
  content: "\f8d1"
}

.sym-zco-s:before {
  content: "\f8d2"
}

.sym-zco:before {
  content: "\f8d3"
}

.sym-zec-s:before {
  content: "\f8d4"
}

.sym-zec:before {
  content: "\f8d5"
}

.sym-zen-s:before {
  content: "\f8d6"
}

.sym-zen:before {
  content: "\f8d7"
}

.sym-zil-s:before {
  content: "\f8d8"
}

.sym-zil:before {
  content: "\f8d9"
}

.sym-zks-s:before {
  content: "\f8da"
}

.sym-zks:before {
  content: "\f8db"
}

.sym-zla-s:before {
  content: "\f8dc"
}

.sym-zla:before {
  content: "\f8dd"
}

.sym-zlk:before {
  content: "\f8de"
}

.sym-zpr-s:before {
  content: "\f8df"
}

.sym-zpr:before {
  content: "\f8e0"
}

.sym-zpt-s:before {
  content: "\f8e1"
}

.sym-zpt:before {
  content: "\f8e2"
}

.sym-zrc-s:before {
  content: "\f8e3"
}

.sym-zrc:before {
  content: "\f8e4"
}

.sym-zrx-s:before {
  content: "\f8e5"
}

.sym-zrx:before {
  content: "\f8e6"
}

.sym-zsc-s:before {
  content: "\f8e7"
}

.sym-zsc:before {
  content: "\f8e8"
}

.sym-ztg-s:before {
  content: "\f8e9"
}

.sym-ztg:before {
  content: "\f8ea"
}

.cur-anct:before {
  content: "\f1b8"
}

.cur-anct-s:before {
  content: "\f1b7"
}

.cur-aud:before {
  content: "\f1e0"
}

.cur-aud-s:before {
  content: "\f1df"
}

.cur-bnb:before {
  content: "\f243"
}

.cur-bnb-s:before {
  content: "\f242"
}

.sym-xbt:before {
  content: "\f267"
}

.cur-btc:before {
  content: "\f267"
}

.sym-xbt-s:before {
  content: "\f266"
}

.cur-btc-s:before {
  content: "\f266"
}

.cur-busd:before {
  content: "\f285"
}

.cur-busd-s:before {
  content: "\f284"
}

.exc-bitz:before {
  content: "\f289"
}

.cur-bz:before {
  content: "\f289"
}

.exc-bitz-s:before {
  content: "\f288"
}

.cur-bz-s:before {
  content: "\f288"
}

.cur-cad:before {
  content: "\f291"
}

.cur-cad-s:before {
  content: "\f290"
}

.cur-chf:before {
  content: "\f2b1"
}

.cur-chf-s:before {
  content: "\f2b0"
}

.cur-cny:before {
  content: "\f2d5"
}

.cur-cny-s:before {
  content: "\f2d4"
}

.sym-cs:before {
  content: "\f2e7"
}

.sym-cs-s:before {
  content: "\f2e6"
}

.sym-crm:before {
  content: "\f2fb"
}

.sym-crm-s:before {
  content: "\f2fa"
}

.cur-dai:before {
  content: "\f323"
}

.cur-dai-s:before {
  content: "\f322"
}

.sym-xdg:before {
  content: "\f35d"
}

.sym-xdg-s:before {
  content: "\f35c"
}

.cur-eos:before {
  content: "\f3a2"
}

.cur-eos-s:before {
  content: "\f3a1"
}

.sym-eth2:before {
  content: "\f3b2"
}

.sym-eth2s:before {
  content: "\f3b2"
}

.sym-eth2\.s:before {
  content: "\f3b2"
}

.sym-weth:before {
  content: "\f3b2"
}

.cur-eth:before {
  content: "\f3b2"
}

.sym-eth2-s:before {
  content: "\f3b1"
}

.sym-eth2s-s:before {
  content: "\f3b1"
}

.sym-eth2\.s-s:before {
  content: "\f3b1"
}

.sym-weth-s:before {
  content: "\f3b1"
}

.cur-eth-s:before {
  content: "\f3b1"
}

.cur-eur:before {
  content: "\f3ba"
}

.cur-eur-s:before {
  content: "\f3b9"
}

.cur-eurs:before {
  content: "\f3bc"
}

.cur-eurs-s:before {
  content: "\f3bb"
}

.sym-usdt:before {
  content: "\f3be"
}

.cur-usdt:before {
  content: "\f3be"
}

.sym-usdt-s:before {
  content: "\f3bd"
}

.cur-usdt-s:before {
  content: "\f3bd"
}

.exc-kraken:before {
  content: "\f3d2"
}

.exc-kraken-futures:before {
  content: "\f3d2"
}

.exc-kraken-s:before {
  content: "\f3d1"
}

.exc-kraken-futures-s:before {
  content: "\f3d1"
}

.cur-gbp:before {
  content: "\f414"
}

.cur-gbp-s:before {
  content: "\f413"
}

.exc-gemini:before {
  content: "\f44e"
}

.cur-gusd:before {
  content: "\f44e"
}

.exc-gemini-s:before {
  content: "\f44d"
}

.cur-gusd-s:before {
  content: "\f44d"
}

.cur-hkd:before {
  content: "\f46c"
}

.cur-hkd-s:before {
  content: "\f46b"
}

.sym-husd:before {
  content: "\f486"
}

.exc-huobi:before {
  content: "\f486"
}

.cur-ht:before {
  content: "\f486"
}

.sym-husd-s:before {
  content: "\f485"
}

.exc-huobi-s:before {
  content: "\f485"
}

.cur-ht-s:before {
  content: "\f485"
}

.cur-idr:before {
  content: "\f4a4"
}

.cur-idr-s:before {
  content: "\f4a3"
}

.sym-iota:before {
  content: "\f4c4"
}

.sym-iota-s:before {
  content: "\f4c3"
}

.cur-inr:before {
  content: "\f4b8"
}

.cur-inr-s:before {
  content: "\f4b7"
}

.cur-jpy:before {
  content: "\f4d4"
}

.cur-jpy-s:before {
  content: "\f4d3"
}

.cur-krw:before {
  content: "\f4fa"
}

.cur-krw-s:before {
  content: "\f4f9"
}

.sym-medx:before {
  content: "\f55a"
}

.sym-medx-s:before {
  content: "\f559"
}

.cur-mxn:before {
  content: "\f5a6"
}

.cur-mxn-s:before {
  content: "\f5a5"
}

.cur-myr:before {
  content: "\f5a8"
}

.cur-myr-s:before {
  content: "\f5a7"
}

.cur-ngn:before {
  content: "\f5d2"
}

.cur-ngn-s:before {
  content: "\f5d1"
}

.cur-pax:before {
  content: "\f63c"
}

.cur-pax-s:before {
  content: "\f63b"
}

.cur-php:before {
  content: "\f650"
}

.cur-php-s:before {
  content: "\f64f"
}

.cur-pln:before {
  content: "\f666"
}

.cur-pln-s:before {
  content: "\f665"
}

.cur-qash:before {
  content: "\f692"
}

.cur-qash-s:before {
  content: "\f691"
}

.cur-rub:before {
  content: "\f6f2"
}

.cur-rur:before {
  content: "\f6f2"
}

.cur-rub-s:before {
  content: "\f6f1"
}

.cur-rur-s:before {
  content: "\f6f1"
}

.sym-steem:before {
  content: "\f708"
}

.sym-steem-s:before {
  content: "\f707"
}

.sym-xsc:before {
  content: "\f70a"
}

.sym-xsc-s:before {
  content: "\f709"
}

.cur-sgd:before {
  content: "\f726"
}

.cur-sgd-s:before {
  content: "\f725"
}

.sym-storj:before {
  content: "\f73a"
}

.sym-storj-s:before {
  content: "\f739"
}

.sym-tel:before {
  content: "\f7a2"
}

.cur-trx:before {
  content: "\f7df"
}

.cur-trx-s:before {
  content: "\f7de"
}

.cur-tusd:before {
  content: "\f7ef"
}

.cur-tusd-s:before {
  content: "\f7ee"
}

.cur-usd:before {
  content: "\f80b"
}

.cur-usd-s:before {
  content: "\f80a"
}

.cur-usdc:before {
  content: "\f80d"
}

.cur-usdc-s:before {
  content: "\f80c"
}

.sym-vet:before {
  content: "\f827"
}

.sym-vet-s:before {
  content: "\f826"
}

.sym-waxp:before {
  content: "\f857"
}

.sym-waxp-s:before {
  content: "\f856"
}

.cur-xlm:before {
  content: "\f88f"
}

.cur-xlm-s:before {
  content: "\f88e"
}

.cur-xmr:before {
  content: "\f893"
}

.cur-xmr-s:before {
  content: "\f892"
}

.cur-xrp:before {
  content: "\f8a7"
}

.cur-xrp-s:before {
  content: "\f8a6"
}

.cur-zar:before {
  content: "\f8cd"
}

.cur-zar-s:before {
  content: "\f8cc"
}

.exc-binance-us:before {
  content: "\f108"
}

.exc-binance-us-s:before {
  content: "\f107"
}

.exc-mexbt:before {
  content: "\f11e"
}

.exc-mexbt-s:before {
  content: "\f11d"
}

.exc-coinbase-pro:before {
  content: "\f128"
}

.exc-gdax:before {
  content: "\f128"
}

.exc-coinbase-pro-s:before {
  content: "\f127"
}

.exc-gdax-s:before {
  content: "\f127"
}

.exc-quadriga:before {
  content: "\f14c"
}

.exc-quadriga-s:before {
  content: "\f14b"
}

.cur-crc:before {
  content: "\f2ef"
}

.cur-crc-s:before {
  content: "\f2ee"
}

.cur-lak:before {
  content: "\f504"
}

.cur-lak-s:before {
  content: "\f503"
}

.cur-sek:before {
  content: "\f716"
}

.cur-sek-s:before {
  content: "\f715"
}

.cur-thb:before {
  content: "\f7af"
}

.cur-thb-s:before {
  content: "\f7ae"
}

.cur-try:before {
  content: "\f7e1"
}

.cur-try-s:before {
  content: "\f7e0"
}

.cur-uah:before {
  content: "\f7f3"
}

.cur-uah-s:before {
  content: "\f7f2"
}

.exc-ftx:before {
  content: "\f3fc"
}

.exc-ftx-s:before {
  content: "\f3fb"
}

.exc-ftx-us:before {
  content: "\f3fc"
}

.exc-ftx-us-s:before {
  content: "\f3fb"
}

.sym-cgld:before {
  content: "\f2a1"
}

.sym-cgld-s:before {
  content: "\f2a0"
}

.exc-uniswap-v2:before {
  content: "\f805"
}

.exc-uniswap-v2-s:before {
  content: "\f804"
}

.exc-comex:before {
  content: "\f126"
}

.exc-comex-s:before {
  content: "\f125"
}

.sym-kshib:before {
  content: "\f72c"
}

.sym-kshib-s:before {
  content: "\f72b"
}

.sym-easy-s:before {
  content: "\f37a"
}