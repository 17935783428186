@font-face{
  font-family:Iosevka Custom;
  font-display:swap;
  font-weight:400;
  font-stretch:normal;
  font-style:normal;
  src:url(/assets/fonts/iosevka-custom-regular.woff2) format("woff2")
}
@font-face{
  font-family:Iosevka Custom;
  font-display:swap;
  font-weight:700;
  font-stretch:normal;
  font-style:normal;
  src:url(/assets/fonts/iosevka-custom-bold.woff2) format("woff2")
}

body {
  /*font-family: 'Overpass', sans-serif;*/
  font-family: "Iosevka Custom";
  font-size: 12px;
  line-height: 1.5;
  color: #18214D;
  background: #FFFFFF;
  font-weight: 400;
}